import React, { useState, useEffect, useContext, useCallback } from "react";
import * as S from "./styles";
import { CommonLayout, ProductsListLayout } from "layouts";
import { ProductService } from "services";
import { SearchComponent, CategoriesComponent } from "components";
import { GlobalContext } from "context";

const Products = () => {
  const context = useContext(GlobalContext);
  const [swellState] = context.globalSwell;

  const [products, setProducts] = useState(null);

  const categories = swellState?.category?.value?.results || [];

  const getProducts = useCallback(async (params = {}) => {
    setProducts(null);
    const products = await ProductService.getProducts(params);
    setProducts(products.results);
  }, []);

  useEffect(() => {
    getProducts();
  }, [getProducts]);

  return (
    <CommonLayout
      title="Productos"
      subTitle="Encontrá las mejores joyas para brillar"
      pageTitle="Productos"
      pageDescription="Encontrá las mejores joyas para brillar"
    >
      <S.Container>
        <CategoriesComponent categories={categories} />
        <SearchComponent getProducts={getProducts} />
        <ProductsListLayout products={products} />
      </S.Container>
    </CommonLayout>
  );
};

export default Products;
