import styled from "styled-components";
import { breakpoints } from "configs/styles";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const NoProducts = styled.p`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 34px;
`;

export const FeaturedTitle = styled.h3`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 28px;
  text-align: center;
  position: relative;
  z-index: 1;
  :after {
    content: "";
    width: 150px;
    background-color: #fbc6a8;
    height: 5px;
    position: absolute;
    bottom: -25px;
    left: calc(50% - 75px);
  }
  @media (max-width: ${breakpoints.medium}) {
    font-size: 20px;
    margin-bottom: 50px;
    :after {
      height: 3px;
      bottom: -20px;
      left: calc(50% - 75px);
    }
  }
`;
