import styled from "styled-components";
import { breakpoints } from "configs/styles";
import { NavLink } from "react-router-dom";

export const Container = styled(NavLink)`
  width: ${({ minimal }) => (minimal ? "140px" : "240px")};
  max-width: 240px;
  height: ${({ minimal }) => (minimal ? "280px" : "340px")};
  background: #ffffff;
  box-shadow: ${({ minimal }) =>
    minimal ? "none" : "2px 2px 10px rgba(0, 0, 0, 0.25)"};
  padding: 15px;
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: pointer;
  color: #000;
  text-decoration: none;
  &:hover {
    box-shadow: ${({ minimal }) =>
      minimal ? "none" : "2px 2px 15px rgba(0, 0, 0, 0.3)"};
    ${({ hashover }) =>
      hashover &&
      `
    img.thumbnail {
      opacity: 0;
      visibility: hidden;
    }
    & img.hover {
      opacity: 1;
      visibility: visible;
    }`}
  }
  @media (max-width: ${breakpoints.medium}) {
    margin: 0;
    overflow: hidden;
    padding: 15px;
    flex: 0 0 47%;
    height: ${({ minimal }) => (minimal ? "280px" : "360px")};
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: 150px;
  min-height: 150px;
  width: 100%;
  position: relative;
  text-align: center;
  & img {
    width: 100%;
    object-fit: cover;
    height: 100%;
    transition: opacity 0.5s ease;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  & img.thumbnail {
    opacity: 1;
    visibility: visible;
  }
  & img.hover {
    visibility: hidden;
    opacity: 0;
    width: 100%;
  }
  div.no-stock {
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: #00000050;
    color: #fff;
    width: 100%;
    text-align: center;
    padding: 3px;
    text-transform: uppercase;
    font-size: 10px;
  }
  @media (max-width: ${breakpoints.medium}) {
    height: 150px;
    min-height: 150px;
    height: ${({ minimal }) => (minimal ? "110px" : "150px")};
    min-height: ${({ minimal }) => (minimal ? "110px" : "150px")};
  }
`;

export const DescriptionContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h3`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  margin: 15px 0;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-all;
  @media (max-width: ${breakpoints.medium}) {
    font-size: 16px;
    margin: 8px 0;
    -webkit-line-clamp: 2;
  }
`;

export const Description = styled.p`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  flex: 1;
  margin-top: 0;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  @media (max-width: ${breakpoints.medium}) {
    font-size: 12px;
    -webkit-line-clamp: 5;
    line-clamp: 5;
    margin-bottom: 18px;
  }
`;

export const Price = styled.div`
  font-family: "Rajdhani";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  @media (max-width: ${breakpoints.medium}) {
    font-size: 18px;
  }

  ${({ minimal }) =>
    minimal &&
    `
  position: absolute;
  left: 15px;
  bottom: 15px;
  `}
`;

export const LikeButton = styled.div`
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
`;

export const AddButton = styled.div`
  position: absolute;
  right: 15px;
  bottom: 15px;
  cursor: pointer;
  width: 35px;
  height: 35px;
  background: #fbc6a8;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ visible }) => !visible && "visibility: hidden;"}
  > div {
    width: 100%;
    height: 100%;
    border-radius: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      width: 105% !important;
      height: 105% !important;
    }
  }
`;
