import React from "react";
import * as S from "./styles";
import { NavComponent, FooterComponent, SeoComponent } from "components";
import { useLocation } from "react-router-dom";
import { ScrollRestoration } from "react-router-dom";

const Main = ({
  children,
  navBackground = false,
  pageTitle,
  pageDescription,
  image,
  type,
}) => {
  const pathname = useLocation().pathname;

  return (
    <S.Container>
      <SeoComponent
        title={`Coraline Joyas${pageTitle ? ` | ${pageTitle}` : ""}`}
        description={pageDescription}
        image={image}
        type={type}
      />
      <ScrollRestoration />
      <NavComponent active={pathname} navBackground={navBackground} />
      {children}
      <FooterComponent />
    </S.Container>
  );
};

export default Main;
