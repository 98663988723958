import React, { useContext } from "react";
import * as S from "./styles";
import { SidebarComponent, LinkComponent } from "components";
import { CartService } from "services";
import { GlobalContext, DispatchTypes } from "context";
import clsx from "clsx";
import TrashIcon from "resources/icons/trash.svg";
import InfoIcon from "resources/icons/info.svg";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { FREE_SHIPPING_AMOUNT } from "utils/general";
import { ReactComponent as CloseIcon } from "resources/icons/close.svg";
import ClipLoader from "react-spinners/ClipLoader";

const Cart = ({ cart }) => {
  const context = useContext(GlobalContext);
  const [swellState, swellDispatch] = context.globalSwell;
  const items = cart?.items || [];
  const checkoutURL = cart?.checkout_url;

  const openCheckout = () => window.open(checkoutURL, "_blank");

  const setShowCart = (value) => {
    swellDispatch({
      type: DispatchTypes.Swell.SET_SUCCESS,
      entity: "showCart",
      value,
    });
  };

  const handleCloseCart = () => setShowCart(false);

  const handleRemoveItem = async (id) => {
    swellDispatch({ type: DispatchTypes.Swell.SET_START, entity: "cart" });
    const cart = await CartService.removeCartItem(id);
    swellDispatch({
      type: DispatchTypes.Swell.SET_SUCCESS,
      entity: "cart",
      value: cart,
    });
  };

  const handleUpdateItem = async (id, quantity) => {
    swellDispatch({ type: DispatchTypes.Swell.SET_START, entity: "cart" });
    const cart = await CartService.updateCartItem(id, quantity);
    swellDispatch({
      type: DispatchTypes.Swell.SET_SUCCESS,
      entity: "cart",
      value: cart,
    });
  };

  const cleanNode = (text) => {
    const htmlNode = new DOMParser().parseFromString(text, "text/html");
    const result =
      htmlNode?.body?.textContent || htmlNode?.body?.innerText || "";
    return result;
  };

  const calculateShippingPrice = () => {
    if (cart?.shipping?.price === null) {
      if (cart?.sub_total > FREE_SHIPPING_AMOUNT) {
        return 0;
      } else {
        return "--";
      }
    } else {
      return cart?.shipping?.price;
    }
  };

  return (
    <SidebarComponent rtl={true} open={true} handleState={setShowCart}>
      <S.CloseButton onClick={handleCloseCart}>
        <CloseIcon />
      </S.CloseButton>
      {(cart === null || cart?.items?.length === 0) && (
        <S.CartSidebarContainer>
          <S.NoItemsMessage>
            <p>No hay items en la bag</p>
            <LinkComponent
              type="primary"
              label="shop now"
              to="/productos"
              action={handleCloseCart}
            />
          </S.NoItemsMessage>
        </S.CartSidebarContainer>
      )}
      {cart && cart?.items?.length && (
        <S.CartSidebarContainer>
          <S.CartItemsContainer>
            {items.map(({ product, quantity, id }) => (
              <S.CartItem key={id}>
                <div>
                  <img
                    className="productImage"
                    src={product.images[0]?.file?.url}
                    alt="product"
                  />
                </div>
                <div>
                  <h3>{product.name}</h3>
                  <div
                    className="description"
                    dangerouslySetInnerHTML={{ __html: product.description }}
                    title={cleanNode(product.description)}
                  />
                  <p className="price">${product.price}</p>
                </div>
                <div>
                  <S.RemoveItem
                    disabled={swellState?.cart?.loading}
                    onClick={() =>
                      !swellState?.cart?.loading && handleRemoveItem(id)
                    }
                  >
                    <img src={TrashIcon} alt="trash icon" />
                  </S.RemoveItem>
                  <S.QuantityInput isLoading={swellState?.cart?.loading}>
                    <span
                      className={clsx(quantity <= 1 && "disabled")}
                      onClick={() =>
                        quantity > 1 && handleUpdateItem(id, quantity - 1)
                      }
                    >
                      -
                    </span>
                    <span>{quantity}</span>
                    <span
                      className={clsx(
                        quantity >= product.stock_level && "disabled"
                      )}
                      onClick={() =>
                        quantity < product.stock_level &&
                        handleUpdateItem(id, quantity + 1)
                      }
                    >
                      +
                    </span>
                  </S.QuantityInput>
                </div>
              </S.CartItem>
            ))}
          </S.CartItemsContainer>
          <S.CartActionContainer>
            <div className="totals">
              <p>
                <span>Sub total</span>
                <span>
                  {swellState?.cart?.loading ? (
                    <ClipLoader
                      color={"#333"}
                      size={15}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                    />
                  ) : (
                    `$${cart?.sub_total}`
                  )}
                </span>
              </p>
              <p>
                <span>
                  Envío{" "}
                  <img
                    src={InfoIcon}
                    alt="info icon"
                    data-tooltip-id="shipping-tooltip"
                  />
                </span>
                <span>
                  {swellState?.cart?.loading ? (
                    <ClipLoader
                      color={"#333"}
                      size={15}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                    />
                  ) : (
                    `$${calculateShippingPrice()}`
                  )}
                </span>
              </p>
              <p>
                <span>Total</span>
                <span>
                  {swellState?.cart?.loading ? (
                    <ClipLoader
                      color={"#333"}
                      size={15}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                    />
                  ) : (
                    `$${cart?.capture_total}`
                  )}
                </span>
              </p>
            </div>
            <button onClick={openCheckout}>Confirmar Orden</button>
          </S.CartActionContainer>
          <ReactTooltip
            className="tooltip-width"
            id="shipping-tooltip"
            place="right"
            content={`El envío se calcula al momento de confirmar la compra. Envíos gratis para compras mayores a $${FREE_SHIPPING_AMOUNT}.`}
          />
        </S.CartSidebarContainer>
      )}
    </SidebarComponent>
  );
};

export default Cart;
