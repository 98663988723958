import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
} from "react";
import * as S from "./styles";
import LogoNav from "resources/images/logo-nav.svg";
import CartNav from "resources/icons/cart.svg";
import MenuNav from "resources/icons/menu.svg";
import CloseMenuNav from "resources/icons/close.svg";
import { NavLink, useLocation } from "react-router-dom";
import { GlobalContext, DispatchTypes } from "context";
import { CartService } from "services";
import Cart from "./cart";
import { ReactComponent as ArrowIcon } from "resources/icons/arrow.svg";

const ALL_PRODUCTS_LABEL = "Todos";

const Nav = ({ navBackground }) => {
  const context = useContext(GlobalContext);
  const [swellState, swellDispatch] = context.globalSwell;
  const showCart = swellState.showCart.value;

  const location = useLocation();
  const fullLocation = location.hash
    ? `${location.pathname}${location.hash}`
    : location.pathname;

  const timer = useRef(null);

  const [showDesktopMenu, setShowDesktopMenu] = useState(false);
  const [hover, setHover] = useState(false);
  const [subMenuOpen, setSubMenuOpen] = useState(true);

  const cartItems = swellState.cart.value;

  const getCartItems = useCallback(async () => {
    swellDispatch({ type: DispatchTypes.Swell.SET_START, entity: "cart" });
    const cart = await CartService.getCartItems();
    swellDispatch({
      type: DispatchTypes.Swell.SET_SUCCESS,
      entity: "cart",
      value: cart,
    });
  }, [swellDispatch]);

  useEffect(() => {
    getCartItems();
  }, [getCartItems]);

  useEffect(() => {
    if (hover) {
      clearInterval(timer.current);
      setShowDesktopMenu(true);
    } else {
      timer.current = setTimeout(() => {
        setShowDesktopMenu(false);
      }, 400);
    }
  }, [hover]);

  const categories = swellState?.category;
  const categoriesResults = categories?.value?.results;

  const routes = [
    { path: "/", label: "Home" },
    {
      path: "/productos",
      label: "Productos",
      onMouseEnter: () => setHover(true),
      onMouseLeave: () => setHover(false),
      categories: categoriesResults || [],
    },
    { path: "/#nosotros", label: "Nosotros" },
    { path: "/favoritos", label: "Favoritos" },
  ];

  const [background, setBackground] = useState(false);

  const changeBackground = useCallback(() => {
    if (window.scrollY >= 50 || navBackground) {
      setBackground(true);
    } else {
      setBackground(false);
    }
  }, [navBackground]);

  useEffect(() => {
    changeBackground();
    window.addEventListener("scroll", changeBackground);
  }, [changeBackground]);

  const [open, setOpen] = useState(false);

  const handleOpenClose = () => {
    setOpen(!open);
  };

  const setShowCart = (value = false) => {
    swellDispatch({
      type: DispatchTypes.Swell.SET_SUCCESS,
      entity: "showCart",
      value: value,
    });
  };

  const handleOpenCart = () => {
    setShowCart(true);
  };

  const handleCloseMenu = () => {
    setOpen(false);
    setSubMenuOpen(false);
  };

  const openSubMenu = (e) => {
    e.stopPropagation();
    setSubMenuOpen(!subMenuOpen);
  };

  return (
    <S.Container
      background={background}
      showDesktopMenu={showDesktopMenu}
      onMouseLeave={() => setHover(false)}
    >
      {showCart && <Cart cart={cartItems} getCartItems={getCartItems} />}
      <S.MenuButton onClick={handleOpenClose}>
        {open ? (
          <img src={CloseMenuNav} alt="close menu icon" />
        ) : (
          <img src={MenuNav} alt="menu icon" />
        )}
      </S.MenuButton>
      <S.LogoContainer>
        <NavLink to="/">
          <img src={LogoNav} alt="logo" />
        </NavLink>
      </S.LogoContainer>
      <S.MenuContainer open={open}>
        <S.MenuList>
          {routes.map(
            (
              { path, label, onMouseEnter, onMouseLeave, categories },
              index
            ) => (
              <S.MenuItem
                key={`nav-${index}`}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
              >
                <S.MenuLink
                  to={categories ? undefined : path}
                  className={
                    fullLocation === path && !categories ? "active" : "inActive"
                  }
                  onClick={categories ? openSubMenu : handleCloseMenu}
                  categories={categories ? 1 : 0}
                >
                  <span className="item">{label}</span>
                </S.MenuLink>
                {categories && (
                  <S.ArrowDropdown onClick={openSubMenu} open={subMenuOpen}>
                    <ArrowIcon />
                  </S.ArrowDropdown>
                )}
                {categories && (
                  <S.SubMenuList open={subMenuOpen}>
                    <S.SubMenuItem>
                      <S.MenuLink to={`/productos`} onClick={handleCloseMenu}>
                        <span className="item">{ALL_PRODUCTS_LABEL}</span>
                      </S.MenuLink>
                    </S.SubMenuItem>
                    {categories.map(({ name, id, slug }) => (
                      <S.SubMenuItem key={id}>
                        <S.MenuLink
                          to={`/${slug}`}
                          key={`${slug}-${index}`}
                          onClick={handleCloseMenu}
                        >
                          <span className="item">{name}</span>
                        </S.MenuLink>
                      </S.SubMenuItem>
                    ))}
                  </S.SubMenuList>
                )}
              </S.MenuItem>
            )
          )}
        </S.MenuList>
      </S.MenuContainer>
      <S.CartContainer className="cart">
        <span onClick={handleOpenCart}>
          <div className="cart-icon">
            <img src={CartNav} alt="Carrito de compras" />
            {cartItems?.items?.length > 0 && (
              <span className="items-count">{cartItems?.items?.length}</span>
            )}
          </div>
          <span className="cart-title">Bag</span>
        </span>
      </S.CartContainer>
      <S.DesktopMenu
        show={showDesktopMenu}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <div>
          <S.DesktopMenuItem>
            <li>
              <S.DesktopMenuItemLink
                to={`/productos`}
                onClick={() => setShowDesktopMenu(false)}
                className={
                  fullLocation === "/productos" ? "active" : "inActive"
                }
              >
                {ALL_PRODUCTS_LABEL}
              </S.DesktopMenuItemLink>
            </li>
          </S.DesktopMenuItem>
          {categoriesResults &&
            categoriesResults
              .filter(({ top_id }) => top_id === null)
              .map(({ name, slug, path }, index) => (
                <S.DesktopMenuItem key={`destop-menu-${index}`}>
                  <li>
                    <S.DesktopMenuItemLink
                      to={`/${slug}`}
                      key={`${slug}-${index}`}
                      onClick={() => setShowDesktopMenu(false)}
                      className={
                        fullLocation === `/${slug}` ? "active" : "inActive"
                      }
                    >
                      {name}
                    </S.DesktopMenuItemLink>
                  </li>
                </S.DesktopMenuItem>
              ))}
        </div>
      </S.DesktopMenu>
    </S.Container>
  );
};

export default Nav;
