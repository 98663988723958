import React, { useState, useEffect, useCallback } from "react";
import * as S from "./styles";
import { ProductComponent, ProductSkeletonComponent } from "components";
import Slider from "react-slick";

const DEFAULT_PRODUCTS_LOAD = 5;

const defaultSliderSettings = {
  infinite: true,
  speed: 400,
  slidesToShow: 2,
  slidesToScroll: 1,
  autoplay: true,
  arrows: true,
};

const FeaturedList = ({
  products,
  productsCount = DEFAULT_PRODUCTS_LOAD,
  loading,
}) => {
  const [sliderSettings, setSliderSettings] = useState({
    ...defaultSliderSettings,
  });

  const updateSlides = useCallback(() => {
    const maxCount = products ? products.length : DEFAULT_PRODUCTS_LOAD;

    if (window.innerWidth <= 500) {
      setSliderSettings({
        ...defaultSliderSettings,
        slidesToShow: 2,
      });
    } else {
      const numberOfSlides = Math.floor(window.innerWidth / 180);
      const slidesToShow =
        numberOfSlides >= maxCount ? maxCount - 1 : numberOfSlides;
      setSliderSettings({
        ...defaultSliderSettings,
        slidesToShow,
      });
    }
  }, [products]);

  useEffect(() => {
    updateSlides();
  }, [products, updateSlides]);

  return (
    <>
      {products === null || loading ? (
        <S.Container>
          <Slider {...sliderSettings}>
            {[...new Array(productsCount)].map((e, index) => (
              <ProductSkeletonComponent
                minimal={true}
                key={`product-skeleton-${index}`}
              />
            ))}
          </Slider>
        </S.Container>
      ) : (
        <S.Container>
          <Slider {...sliderSettings}>
            {products.map((product, index) => (
              <ProductComponent
                key={`product-${product?.id || index}`}
                {...product}
                minimal={true}
              />
            ))}
          </Slider>
        </S.Container>
      )}
      {products && products.length === 0 && (
        <S.Container>
          <S.NoProducts>No hay productos disponibles</S.NoProducts>
        </S.Container>
      )}
    </>
  );
};

export default FeaturedList;
