import styled from "styled-components";
import { breakpoints } from "configs/styles";

export const Container = styled.div`
  display: flex;
  margin: 10px 0;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 6px 8px;
  width: 100%;
  max-width: 1200px;

  @media (max-width: ${breakpoints.medium}) {
  }
`;

export const SearchInputContainer = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  flex: 1;
  padding-right: 10px;

  .closeIconSearch {
    margin: 0 7px;
  }
`;

export const SearchInput = styled.input`
  color: #333;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  border: none;
  outline: none;
  flex: 1;
  width: 100%;
`;

export const FilterButtonContainer = styled.div`
  display: flex;
  align-items: center;
  color: #f5917b;
  font-family: Poppins;
  font-size: 17px;
  font-style: normal;
  font-weight: 300;
  border-left: 1px solid #999;
  padding: 0 5px 0 13px;
  text-decoration-line: underline;
  cursor: pointer;
`;

export const FilterContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #33333350;
  z-index: 10;
  display: ${({ show }) => (show ? "block" : "none")};
`;

export const SideBar = styled.div`
  background-color: #fff;
  height: 100%;
  width: 80%;
  max-width: 400px;
  padding: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const SideBarHeader = styled.div`
  display: flex;
  align-items: center;
`;

export const SideBarHeaderClose = styled.div`
  position: absolute;
  right: 15px;
  top: 15px;
`;

export const SideBarHeaderTitle = styled.h3`
  flex: 1;
  text-align: center;
  color: #000;
  text-align: center;
  font-family: Poppins;
  font-size: 17px;
  font-style: normal;
  font-weight: 300;
`;

export const SideBarContainer = styled.div`
  padding: 0 20px;
  flex: 1;
  gap: 20px;
  display: flex;
  flex-direction: column;
`;

export const AttributeContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  h4 {
    color: #f5917b;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    margin: 0;
  }
`;

export const SideBarActionContainer = styled.div`
  padding: 0 20px;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
  .clearFilters {
    color: #f5917b;
    font-family: Poppins;
    font-size: 17px;
    font-style: normal;
    font-weight: 300;
    text-decoration-line: underline;
    border: none;
    outline: none;
    background: none;
  }
`;
