import React, { useEffect, useContext, useRef } from "react";
import * as S from "./styles";
import LikeIcon from "resources/icons/like.svg";
import LikeFillIcon from "resources/icons/likeFill.svg";
import ImagePlaceholder from "resources/images/placeholder-image.jpg";
import addAnimation from "./add.json";
import { CartService, ProductService } from "services";
import { GlobalContext, DispatchTypes } from "context";
import lottie from "lottie-web";

const Product = ({
  name,
  price,
  images,
  meta_description,
  slug,
  id,
  stock_level,
  minimal = false,
}) => {
  const context = useContext(GlobalContext);
  const [swellState, swellDispatch] = context.globalSwell;
  const [storeState, storeDispatch] = context.globalStore;

  const favourite = storeState.wishList.value.find((product) => product === id);

  const cartItems = swellState.cart.value;
  const findFromCart = cartItems?.items?.find((item) => item.product.id === id);
  const maxValue = findFromCart
    ? stock_level - findFromCart.quantity
    : stock_level;

  const addButtonRef = useRef(null);

  useEffect(() => {
    lottie.loadAnimation({
      name: `addButtonAnimation-${id}`,
      renderer: "svg",
      loop: false,
      autoplay: false,
      animationData: addAnimation,
      container: addButtonRef.current,
    });

    return () => {
      lottie.destroy();
    };
  }, [id]);

  const thumbnail = images && images[0]?.file?.url;
  const hoverThumbnail = images && images[1]?.file?.url;

  const addItemToCart = async (e) => {
    if (maxValue >= 1) {
      e.stopPropagation();
      e.preventDefault();
      lottie.play(`addButtonAnimation-${id}`);
      setTimeout(() => {
        lottie.stop(`addButtonAnimation-${id}`);
      }, 1500);

      swellDispatch({ type: DispatchTypes.Swell.SET_START, entity: "cart" });

      if (findFromCart) {
        const newQuantity =
          findFromCart.quantity + 1 >= stock_level
            ? stock_level
            : findFromCart.quantity + 1;
        const updateCart = await CartService.updateCartItem(
          findFromCart.id,
          newQuantity
        );
        swellDispatch({
          type: DispatchTypes.Swell.SET_SUCCESS,
          entity: "cart",
          value: updateCart,
        });
      } else {
        const cart = await CartService.addCartItem({ product_id: id });
        if (!cart.errors) {
          swellDispatch({
            type: DispatchTypes.Swell.SET_SUCCESS,
            entity: "cart",
            value: cart,
          });
        }
      }
    }
  };

  const toggleFavourite = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const newWishList = favourite
      ? ProductService.removeFromFavourites(id)
      : ProductService.addToFavourites(id);
    storeDispatch({
      type: DispatchTypes.Store.SET_SUCCESS,
      entity: "wishList",
      value: newWishList,
    });
  };

  return (
    <S.Container
      hashover={hoverThumbnail !== undefined ? 1 : 0}
      minimal={minimal ? 1 : 0}
      to={`/producto/${slug}`}
    >
      <S.ImageContainer minimal={minimal ? 1 : 0}>
        <img
          className="thumbnail"
          src={thumbnail || ImagePlaceholder}
          alt={`imagen ${name}`}
        />
        {hoverThumbnail && (
          <img
            className="hover"
            src={hoverThumbnail || ImagePlaceholder}
            alt={`imagen ${name}`}
          />
        )}
        {stock_level === 0 && <div className="no-stock">Agotado</div>}
      </S.ImageContainer>
      <S.DescriptionContainer>
        <S.Title title={name}>{name}</S.Title>
        {minimal === false && (
          <S.Description title={meta_description}>
            {meta_description}
          </S.Description>
        )}
        <S.Price minimal={minimal}>${price}</S.Price>
      </S.DescriptionContainer>
      <S.LikeButton>
        <img
          src={favourite ? LikeFillIcon : LikeIcon}
          alt="like button"
          onClick={toggleFavourite}
        />
      </S.LikeButton>
      <S.AddButton
        onClick={addItemToCart}
        disabled={maxValue < 1 || swellState?.cart?.loading}
        visible={maxValue >= 1}
      >
        <div id={`addButtonAnimation-${id}`} ref={addButtonRef} />
      </S.AddButton>
    </S.Container>
  );
};

export default Product;
