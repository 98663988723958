import React, { useContext, useEffect, useRef } from "react";
import * as S from "./styles";
import { GlobalContext, DispatchTypes } from "context";

const Sidebar = ({ children, rtl, open, handleState }) => {
  const wrapperRef = useRef(null);
  const context = useContext(GlobalContext);
  const [swellState, swellDispatch] = context.globalSwell;

  const showCart = swellState.showCart.value;

  const setShowCart = (value) => {
    swellDispatch({
      type: DispatchTypes.Swell.SET_SUCCESS,
      entity: "showCart",
      value,
    });
  };

  const useOutsideCart = (ref) => {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowCart(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };

  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  useOutsideCart(wrapperRef);

  return (
    <>
      <S.Overlay />
      <S.Container ref={wrapperRef} rtl={rtl} open={showCart}>
        {children}
      </S.Container>
    </>
  );
};

export default Sidebar;
