import React from "react";
import * as S from "./styles";
import { DiamondComponent } from "components";
import { useNavigate } from "react-router-dom";

const Featured = ({ title, categories }) => {
  const navigate = useNavigate();

  const categoriesResult = categories?.value?.results?.filter(
    ({ top_id }) => top_id === null
  );
  const categoriesCount = categoriesResult?.length * 2 || 0;

  return (
    <S.Container>
      <S.Title>{title}</S.Title>
      <S.Content>
        <S.ImagesContainer categoriesLength={categoriesCount}>
          {categoriesResult &&
            [...categoriesResult, ...categoriesResult, ...categoriesResult].map(
              ({ name, id, images, slug }, index) => (
                <div
                  key={`${index}-${id}`}
                  onClick={() => navigate(`/${slug}`)}
                >
                  <h2>{name}</h2>
                  <DiamondComponent
                    rotated={index % 2 !== 0}
                    image={images && images[0]?.file?.url}
                    index={index}
                  />
                </div>
              )
            )}
        </S.ImagesContainer>
      </S.Content>
    </S.Container>
  );
};

export default Featured;
