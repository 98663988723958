import React, { useState, useEffect, useCallback, useContext } from "react";
import * as S from "./styles";
import { CommonLayout } from "layouts";
import { ProductService, CartService } from "services";
import { useParams } from "react-router-dom";
import {
  ButtonComponent,
  ImagesComponent,
  QuantitySelectorComponent,
  LinkComponent,
} from "components";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { removeDuplicates } from "utils/array";
import { GlobalContext, DispatchTypes } from "context";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
import { FREE_SHIPPING_AMOUNT, WP_CONTACT_NUMBER } from "utils/general";

const Product = () => {
  const fullURL = window.location.href;
  const context = useContext(GlobalContext);
  const [swellState, swellDispatch] = context.globalSwell;
  const [storeState, storeDispatch] = context.globalStore;

  const cartItems = swellState.cart.value;

  const { productId } = useParams();
  const [product, setProduct] = useState({});
  const [quantity, setQuantity] = useState(1);

  const nameFromSlug = (slug) => {
    const name = slug.replaceAll("-", " ");
    return name.charAt(0).toUpperCase() + name.slice(1);
  };

  const favourite = storeState.wishList?.value?.find(
    (id) => id === product?.id
  );

  const findFromCart = cartItems?.items?.find(
    (item) => item.product.id === product?.id
  );

  const maxValue = findFromCart
    ? product?.stock_level - findFromCart.quantity
    : product?.stock_level;

  useEffect(() => {
    setQuantity(maxValue === 0 ? 0 : quantity || 1);
  }, [maxValue, quantity]);

  const getProduct = useCallback(async () => {
    const product = await ProductService.getProductBySlug(productId);
    setProduct(product);
  }, [productId]);

  useEffect(() => {
    getProduct();
  }, [getProduct]);

  const images = product?.images?.map(({ file, id }) => ({
    ...file,
    id,
  }));
  const uniqueImages = images && removeDuplicates(images, "md5");

  const handleAddToCart = async () => {
    if (product) {
      swellDispatch({ type: DispatchTypes.Swell.SET_START, entity: "cart" });

      if (findFromCart) {
        const newQuantity =
          findFromCart.quantity + quantity >= product.stock_level
            ? product.stock_level
            : findFromCart.quantity + quantity;
        const updateCart = await CartService.updateCartItem(
          findFromCart.id,
          newQuantity
        );
        swellDispatch({
          type: DispatchTypes.Swell.SET_SUCCESS,
          entity: "cart",
          value: updateCart,
        });
      } else {
        const cart = await CartService.addCartItem({
          product_id: product.id,
          quantity,
        });

        if (!cart.errors) {
          swellDispatch({
            type: DispatchTypes.Swell.SET_SUCCESS,
            entity: "cart",
            value: cart,
          });
        }
      }
    }
  };

  const toggleFavourite = (e) => {
    if (product) {
      e.stopPropagation();
      const newWishList = favourite
        ? ProductService.removeFromFavourites(product.id)
        : ProductService.addToFavourites(product.id);
      storeDispatch({
        type: DispatchTypes.Store.SET_SUCCESS,
        entity: "wishList",
        value: newWishList,
      });
    }
  };

  const setShowCart = (value) => {
    swellDispatch({
      type: DispatchTypes.Swell.SET_SUCCESS,
      entity: "showCart",
      value,
    });
  };

  const attributes = product?.attributes
    ? Object.values(product?.attributes)
    : [];

  return (
    <CommonLayout
      title={product?.name || nameFromSlug(productId)}
      subTitle={product?.meta_description || product?.description}
      pageTitle={product?.name}
      pageDescription={product?.meta_description || product?.description}
      image={uniqueImages && uniqueImages[0]?.url}
      type="product"
      breadcrumbs={true}
      breadcrumbsCategory={product?.categories?.[0]?.slug}
      loading={Object.keys(product || {}).length === 0}
    >
      <S.Container>
        {product ? (
          <>
            <S.ImageContainer>
              <ImagesComponent images={uniqueImages} />
            </S.ImageContainer>
            <S.Content>
              <S.Title title={product?.name}>
                {product.name || <Skeleton />}
              </S.Title>
              <S.Description>
                {product.description ? (
                  <div
                    dangerouslySetInnerHTML={{ __html: product.description }}
                  />
                ) : (
                  <Skeleton count={4} />
                )}
              </S.Description>
              <S.Price>
                {product ? (
                  product.price && `$${product.price}`
                ) : (
                  <Skeleton count={1} />
                )}
              </S.Price>

              <S.AttibuteContainer>
                <S.AttibuteTitle>Cantidad</S.AttibuteTitle>
                {product?.stock_level === 0 ? (
                  <S.NoStockContainer>
                    <h3>Sin stock</h3>
                    <p>Este producto se encuentra momentaneamente agotado.</p>
                    <p>
                      <a
                        href={`https://wa.me/${WP_CONTACT_NUMBER}?text=Hola! Estoy interesad@ en ${product?.name}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Comunicate con nosotros para consultar disponibilidad
                        aqui.
                      </a>
                    </p>
                  </S.NoStockContainer>
                ) : (
                  <S.AttibuteContent>
                    <QuantitySelectorComponent
                      value={quantity}
                      maxValue={maxValue || 0}
                      onChange={setQuantity}
                    />
                  </S.AttibuteContent>
                )}
              </S.AttibuteContainer>

              {findFromCart && (
                <p className="cart-quantity">
                  {findFromCart.quantity} agregado
                  {findFromCart.quantity > 1 && "s"} en la bag
                </p>
              )}

              <ButtonComponent
                type="addToCart"
                label={swellState?.cart?.loading ? "Agregando..." : "Agregar"}
                primaryAction={handleAddToCart}
                disabled={quantity <= 0 || swellState?.cart?.loading}
                secondaryAction={toggleFavourite}
                favourite={favourite}
              />

              {findFromCart && (
                <div className="show-cart-container">
                  <ButtonComponent
                    type="secondary"
                    label="Finalizar compra"
                    primaryAction={() => setShowCart(true)}
                    disabled={swellState?.cart?.loading}
                    favourite={favourite}
                  />
                </div>
              )}

              {product.stock_level > 0 && product.stock_level <= 2 && (
                <S.AvailableQuantity>
                  Apurate! Solo queda{product.stock_level > 1 && "n"}{" "}
                  <strong>{product.stock_level}</strong> disponible
                  {product.stock_level > 1 && "s"}.
                </S.AvailableQuantity>
              )}

              {attributes.length > 0 && (
                <S.AttibuteContainer>
                  <S.AttibuteTitle>Detalles</S.AttibuteTitle>
                  <S.AttibuteContent>
                    {attributes
                      .filter(({ visible }) => visible)
                      .map((attribute) => (
                        <p key={attribute.id}>
                          <strong>{attribute.name}: </strong>
                          <span>{attribute.value.join(", ")}</span>
                        </p>
                      ))}
                  </S.AttibuteContent>
                </S.AttibuteContainer>
              )}

              <S.AttibuteContainer>
                <S.AttibuteTitle>Envío</S.AttibuteTitle>
                <S.AttibuteContent>
                  <p>Envíos mediante la agencia de su preferencia.</p>
                  <p>
                    Envíos gratis en compras mayores a ${FREE_SHIPPING_AMOUNT}.
                  </p>
                  <p>
                    <strong>Enviamos a todo el pais 🇺🇾</strong>
                  </p>
                </S.AttibuteContent>
              </S.AttibuteContainer>

              <S.AttibuteContainer>
                <S.AttibuteTitle>Compartir</S.AttibuteTitle>
                <S.AttibuteContent className="share">
                  <WhatsappShareButton url={fullURL}>
                    <WhatsappIcon size={43} round={true} />
                  </WhatsappShareButton>
                  <FacebookShareButton url={fullURL}>
                    <FacebookIcon size={43} round={true} />
                  </FacebookShareButton>
                  <TwitterShareButton url={fullURL}>
                    <TwitterIcon size={43} round={true} />
                  </TwitterShareButton>
                </S.AttibuteContent>
              </S.AttibuteContainer>
            </S.Content>
          </>
        ) : (
          <S.NoProducts>
            <strong>Oops!</strong>No pudimos encontrar el producto que estás
            buscando.
            <LinkComponent type="primary" label="Vovler" to="/productos" />
          </S.NoProducts>
        )}
      </S.Container>
    </CommonLayout>
  );
};

export default Product;
