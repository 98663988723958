import { DispatchTypes } from "..";

const internalSwellInitialState = {
  error: null,
  category: {
    loading: true,
    value: [],
    error: null,
  },
  attributes: {
    loading: false,
    value: [],
    error: null,
  },
  cart: {
    loading: false,
    value: [],
    error: null,
  },
  featured: {
    loading: false,
    value: null,
    error: null,
  },
  showCart: {
    loading: false,
    value: false,
    error: null,
  },
  loading: false,
};

export const swellInitialState = { ...internalSwellInitialState };

const SwellReducer = (currentState, action) => {
  switch (action.type) {
    case DispatchTypes.Swell.SET_START:
      currentState[action.entity].loading = true;
      return { ...currentState };
    case DispatchTypes.Swell.SET_SUCCESS:
      currentState[action.entity].value = action.value;
      currentState[action.entity].loading = false;
      return { ...currentState };
    case DispatchTypes.Swell.SET_ERROR:
      currentState[action.entity].loading = false;
      currentState[action.entity].error = action.value;
      return { ...currentState };
    case DispatchTypes.Global.RESET:
      currentState = { ...swellInitialState };
      return { ...currentState };
    default:
      return currentState;
  }
};

export default SwellReducer;
