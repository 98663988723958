import React, { useEffect, useState, useCallback, useContext } from "react";
import * as S from "./styles";
import { CommonLayout, ProductsListLayout } from "layouts";
import { useParams, Navigate } from "react-router-dom";
import { ProductService } from "services";
import { GlobalContext } from "context";
import { SearchComponent } from "components";

const Category = () => {
  const { categoryId } = useParams();

  const context = useContext(GlobalContext);
  const [swellState] = context.globalSwell;

  const findCategory = swellState?.category?.value?.results?.find(
    ({ slug }) => slug === categoryId
  );

  const categoryExists =
    swellState.category.loading === true ? true : findCategory !== undefined;

  const [products, setProducts] = useState(null);

  const getProducts = useCallback(
    async (params) => {
      setProducts(null);
      const products = await ProductService.getProductsByCategory({
        category: categoryId,
        ...params,
      });
      setProducts(products?.results || []);
    },
    [categoryId]
  );

  useEffect(() => {
    if (
      swellState.category.value?.results &&
      swellState.category.loading === false
    ) {
      getProducts();
    }
  }, [getProducts, swellState.category, swellState.category.loading]);

  useEffect(() => {
    setProducts(null);
  }, [categoryId]);

  const isLoading = swellState.category.loading || products === null;

  if (categoryExists === false) {
    return <Navigate to="/404" />;
  }

  return (
    <CommonLayout
      title={findCategory?.name}
      subTitle={findCategory?.meta_description}
      pageTitle={findCategory?.name}
      pageDescription={findCategory?.description}
      breadcrumbs={true}
    >
      <S.Container>
        <SearchComponent getProducts={getProducts} />
        <ProductsListLayout
          products={products}
          productsCount={findCategory?.products?.count}
          loading={isLoading}
        />
      </S.Container>
    </CommonLayout>
  );
};

export default Category;
