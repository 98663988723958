import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import * as S from "./styles";
import { CommonLayout } from "layouts";
import { CartService } from "services";
import CheckIcon from "resources/icons/check.svg";
import { LinkComponent } from "components";
import { PAYMENT_METHODS, LINK_CHECKOUT, ORDER_STATUS } from "utils/general";

const Checkout = () => {
  const { checkoutId } = useParams();

  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(true);

  const getOrder = async (id) => {
    try {
      const orderFromService = await CartService.getOrder(id);
      setOrder(orderFromService);
    } catch (error) {}
    setLoading(false);
  };

  useEffect(() => {
    if (checkoutId) {
      getOrder(checkoutId);
    }
  }, [checkoutId]);

  const handlePay = () => {
    window.open(LINK_CHECKOUT, "_blank");
  };

  return (
    <CommonLayout
      title="Compra"
      subTitle="Gracias por tu compra!"
      pageTitle="Compra"
      pageDescription="Gracias por tu compra!"
    >
      <S.Container>
        {loading ? (
          <S.Loader>Cargando...</S.Loader>
        ) : order ? (
          <>
            <S.OrderNumber>
              <img src={CheckIcon} alt="check" /> Órden #{order.number}
            </S.OrderNumber>
            <S.OrderMessage>{ORDER_STATUS[order.status]}.</S.OrderMessage>
            {order.status === "canceled" && (
              <p>
                Si tienes alguna duda comunicate a{" "}
                <a className="mailto" href="mailto:contacto@coraline.uy">
                  contacto@coraline.uy
                </a>
                .
              </p>
            )}
            {order.status === "payment_pending" &&
              order.billing.method === "mercado_pago" && (
                <S.MercadoPagoContainer>
                  <h4>Paga con MercadoPago</h4>
                  <ol>
                    <li>Ingresá al link de pago</li>
                    <li>
                      Agregá el monto de tu compra (${order.grand_total}) y
                      segui los pasos
                    </li>
                    <li>
                      Envianos el comprobante a{" "}
                      <a className="mailto" href="mailto:contacto@coraline.uy">
                        contacto@coraline.uy
                      </a>{" "}
                      y listo!
                    </li>
                  </ol>
                  <LinkComponent
                    type="primary"
                    label="Pagar"
                    action={handlePay}
                  />
                </S.MercadoPagoContainer>
              )}
            <S.InfoContainer>
              <h3>Información de compra</h3>
              <div className="columns-container">
                <div>
                  <div>
                    <h4>Items</h4>
                    <div className="items-container">
                      <ul>
                        {order.items.map((item) => (
                          <li key={item.id}>
                            <img
                              alt="item"
                              src={item.product.images[0].file.url}
                            />
                            <p>
                              {item.product.name} (${item.price_total})
                            </p>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>

                <div>
                  <div>
                    <h4>Envío</h4>
                    <p>${order.shipment_total}</p>
                  </div>
                  <div>
                    <h4>Total</h4>
                    <p>${order.grand_total}</p>
                  </div>
                </div>
              </div>
              <h3>Información del cliente</h3>

              <div className="columns-container">
                <div>
                  <div>
                    <h4>Dirección de envío</h4>
                    <p>
                      {order.shipping.first_name} {order.shipping.last_name}
                    </p>
                    <p>{order.shipping.address1}</p>
                    <p>
                      {order.shipping.city}, {order.shipping.zip} (
                      {order.shipping.country})
                    </p>
                    <p>{order.shipping.phone}</p>
                  </div>
                  <div>
                    <h4>Método de envío</h4>
                    <p>{order.shipping.service_name}</p>
                  </div>
                  <div>
                    <h4>Email</h4>
                    <p>{order.account.email}</p>
                  </div>
                </div>
                <div>
                  <div>
                    <h4>Método de pago</h4>
                    <p>{PAYMENT_METHODS[order.billing.method]}</p>
                  </div>
                  <div>
                    <h4>Instrucciones de pago</h4>
                    <p>{order.billing.instructions}</p>
                  </div>
                </div>
              </div>
            </S.InfoContainer>
          </>
        ) : (
          <S.NotFound>La órden buscada no existe</S.NotFound>
        )}
      </S.Container>
    </CommonLayout>
  );
};

export default Checkout;
