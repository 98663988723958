import React from "react";
import * as S from "./styles";

const Link = ({ type, label, to, action, buttonType, disabled }) => {
  const linkType = {
    primary: (
      <S.LinkPrimary
        onClick={action}
        to={to}
        type={buttonType}
        disabled={disabled}
      >
        {label}
      </S.LinkPrimary>
    ),
    secondary: (
      <S.LinkSecondary
        onClick={action}
        to={to}
        type={buttonType}
        disabled={disabled}
      >
        {label}
      </S.LinkSecondary>
    ),
    button: (
      <S.Button onClick={action} to={to} type={buttonType} disabled={disabled}>
        {label}
      </S.Button>
    ),
  }[type];
  return <S.Container>{linkType}</S.Container>;
};

export default Link;
