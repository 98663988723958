import React, { useContext, useRef } from "react";
import * as S from "./styles";
import { LinkComponent, FeaturedComponent } from "components";
import { MainLayout } from "layouts";
import { GlobalContext } from "context";
import Slider from "react-slick";
import scrollIcon from "resources/icons/scroll.json";
import Lottie from "lottie-react";
import { FeaturedListLayout } from "layouts";
import SliderPreload from "resources/images/slider-preload.webp";

const Home = () => {
  const context = useContext(GlobalContext);
  const [swellState] = context.globalSwell;

  const sliderRef = useRef(null);
  const dragPositionX = useRef(0);
  const dragPositionY = useRef(0);

  const categoriesResult =
    swellState?.category?.value?.results?.filter(
      ({ top_id }) => top_id === null
    ) || [];

  const categoriesWithImage = categoriesResult
    ? categoriesResult
        .filter(({ images }) => images[0]?.file?.url)
        .map(({ images }) => images[0].file.url)
    : [];

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
  };

  const onTitleDrag = (e) => {
    const touchObj = e.changedTouches[0];
    if (e._reactName === "onTouchStart") {
      const originalPositionX = touchObj.clientX;
      const originalPositionY = touchObj.clientY;
      dragPositionX.current = originalPositionX;
      dragPositionY.current = originalPositionY;
    } else if (e._reactName === "onTouchMove") {
      const distX = touchObj.clientX - dragPositionX.current;
      const distY = parseInt(touchObj.clientY - dragPositionY.current);
      const yOverflow = 5;
      if (distY >= -yOverflow && distY <= yOverflow) {
        if (distX < 0) {
          sliderRef.current.slickNext();
        } else {
          sliderRef.current.slickPrev();
        }
      }
    }
  };

  return (
    <MainLayout
      navBackground={true}
      pageDescription="La belleza está en los detalles"
    >
      <S.Container>
        <Slider {...sliderSettings} ref={sliderRef}>
          {categoriesWithImage.length === 0 ? (
            <div key={"slider-image-preload"}>
              <img src={SliderPreload} alt={"slider-preload"} />
            </div>
          ) : (
            categoriesWithImage.map((url, index) => (
              <div key={`slider-image-${index}`}>
                <img src={url} alt={`slider-${index}`} />
              </div>
            ))
          )}
        </Slider>
        <S.TitleContainer onTouchStart={onTitleDrag} onTouchMove={onTitleDrag}>
          <div>
            <S.Title>
              La <strong>belleza</strong> está en los <strong>detalles</strong>.
            </S.Title>
            <S.ButtonsContainer>
              <LinkComponent type="primary" label="shop now" to="/productos" />
              <LinkComponent type="secondary" label="contacto" to="/contacto" />
            </S.ButtonsContainer>
          </div>
          <div className="scroll-animation">
            <Lottie animationData={scrollIcon} loop={true} />
          </div>
        </S.TitleContainer>
      </S.Container>

      <S.FeaturedProductsContainer id="destacados">
        <S.AboutTitle>Destacados</S.AboutTitle>
        <S.AboutContent>
          <FeaturedListLayout
            products={swellState.featured.value}
            loading={swellState.featured.loading}
          />
        </S.AboutContent>
      </S.FeaturedProductsContainer>

      <S.AboutContainer id="nosotros">
        <S.AboutTitle>Sobre Nosotros</S.AboutTitle>
        <S.AboutContent>
          <p>
            Somos un emprendimiento familiar, nos inspiramos para brindar el
            mejor servicio, ofrecemos joyas minimalistas y elegantes en{" "}
            <strong>Plata 925</strong> y <strong>Acero de alta calidad</strong>.
          </p>
          <p>
            Nuestro objetivo es destacar tu estilo y belleza natural, ofrecerte
            joyas que se adapten a cualquier estilo y ocasión. Creemos que la
            joyería es una expresión de la personalidad y la individualidad de
            cada persona, por lo que nos esforzamos por ofrecer opciones
            versátiles y atractivas para cualquier edad. Nos enorgullece ofrecer
            una experiencia de compra fácil y accesible para nuestros clientes.
          </p>
          <p>
            Nuestra tienda en línea está diseñada para facilitar la navegación y
            la compra, y siempre estamos disponibles para ayudarte en cualquier
            momento. Esperamos que encuentres la pieza perfecta para
            complementar tu estilo en nuestra colección.
          </p>
          <p className="thanks">
            <strong>¡Gracias por elegir Coraline!</strong>
          </p>
        </S.AboutContent>
      </S.AboutContainer>

      <S.FeaturedContainer>
        <FeaturedComponent
          title="Nuestra colección"
          categories={swellState.category}
        />
      </S.FeaturedContainer>
    </MainLayout>
  );
};

export default Home;
