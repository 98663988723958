import React from "react";
import * as S from "./styles";

const Check = ({ id, label, name, onChange, checked }) => {
  return (
    <S.Container>
      <input
        id={id}
        type="checkbox"
        name={name}
        onChange={onChange}
        checked={checked}
      />
      <label htmlFor={id}>{label}</label>
    </S.Container>
  );
};

export default Check;
