import styled from "styled-components";
import { breakpoints } from "configs/styles";

export const Container = styled.div`
  width: ${({ minimal }) => (minimal ? "140px" : "240px")};
  height: ${({ minimal }) => (minimal ? "300px" : "340px")};
  background: #fefefe;
  padding: 15px;
  display: flex;
  flex-direction: column;
  @media (max-width: ${breakpoints.medium}) {
    margin: 0;
    overflow: hidden;
    padding: 15px;
    flex: 0 0 47%;
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: 150px;
  min-height: 150px;
  width: 100%;
  background-color: #eee;
`;

export const DescriptionContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h3`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  margin: 15px 0;
  background-color: #eee;
  height: 30px;
`;

export const Description = styled.p`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  flex: 1;
  margin-top: 0;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  background-color: #eee;
`;

export const Price = styled.div`
  font-family: "Rajdhani";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  background-color: #eee;
  height: 20px;
  width: 50%;
`;

export const LikeButton = styled.div`
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
`;

export const AddButton = styled.div`
  position: absolute;
  right: 15px;
  bottom: 15px;
  cursor: pointer;
  width: 35px;
  height: 35px;
  background: #fbc6a8;
  border-radius: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
