import swell from "swell-js";

export const createNewsletter = async ({ email }) => {
  const newAccount = await swell.account.create({
    email,
    email_optin: true,
  });
  await swell.account.logout();
  return newAccount;
};
