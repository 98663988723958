import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 60px 30px;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1200px;
`;

export const Form = styled.form`
  > div {
    display: flex;
    flex-wrap: wrap;
    column-gap: 30px;

    &.submit {
      display: flex;
      flex-direction: column;
      button {
        font-size: 16px;
        width: 130px;
        height: 50px;
        margin-top: 20px;
      }
    }
  }
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  gap: 10px;
  min-width: 300px;
  flex: 1;
  &.text {
    max-width: 100%;
  }
`;

export const Label = styled.label`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
`;

export const Input = styled.input`
  outline: none;
  border: none;
  border-radius: 4px;
  background-color: #eee;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  padding: 10px;
  width: 100%;
`;

export const TextArea = styled.textarea`
  outline: none;
  border: none;
  border-radius: 4px;
  background-color: #eee;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  padding: 10px;
  width: 100%;
`;

export const ThanksMsg = styled.p`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  margin-top: 40px;
  color: #f5917b;
`;

export const ErrorLabel = styled.p`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  color: #ff3333;
  margin: 10px 0;
`;
