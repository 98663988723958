import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import {
  HomeView,
  ProductsView,
  ContactView,
  ProductView,
  CategoryView,
  CheckoutView,
  FavouritesView,
  PageNotFoundView,
} from "views";

const router = createBrowserRouter([
  { path: "/", element: <HomeView /> },
  { path: "/productos", element: <ProductsView /> },
  { path: "/contacto", element: <ContactView /> },
  { path: "/favoritos", element: <FavouritesView /> },
  { path: "/producto/:productId", element: <ProductView /> },
  { path: "/compra/:checkoutId", element: <CheckoutView /> },
  { path: "/:categoryId", element: <CategoryView /> },
  { path: "/404", element: <PageNotFoundView /> },
  { path: "*", element: <PageNotFoundView /> },
]);

const RoutesComponent = () => {
  return (
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  );
};

export default RoutesComponent;
