import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  overflow: hidden;
  flex: 1;
  gap: 10px;
`;

export const ListContainer = styled.ul`
  display: flex;
  flex: 1;
  margin: 0;
  padding: 0;
  gap: 10px;
  flex-direction: column;
`;

export const ListItem = styled.li`
  display: flex;
  width: 100px;
  height: 100px;
  border: 1px solid #333333;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  &:hover:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background-color: #00000010;
    width: 100%;
    height: 100%;
  }
  &.selected {
    :after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      background-color: #00000010;
      width: 100%;
      height: 100%;
    }
  }
  img {
    width: 100px;
    height: 100px;
    max-width: 100px;
    max-height: 100px;
    object-fit: cover;
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  flex: 4;
  overflow: hidden;
  align-items: flex-start;
  overflow: hidden;
  > span {
    width: 100%;
  }
  > div {
    width: 100%;
  }
  img {
    width: 100%;
    height: auto;
  }
`;
