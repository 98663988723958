import React from "react";
import * as S from "./styles";
import { ReactComponent as ArrowIcon } from "resources/icons/arrow.svg";
import ClipLoader from "react-spinners/ClipLoader";

const Input = ({
  type,
  inputType,
  placeholder,
  onChange,
  onAction,
  value,
  loading,
  disabled,
}) => {
  const componentType = {
    withButton: (
      <S.WithActionContainer>
        <S.WithActionInput
          type={inputType}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          disabled={disabled || loading}
        />
        <S.WithActionButton onClick={onAction}>
          {loading ? (
            <ClipLoader
              color={"#FFF"}
              size={15}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          ) : (
            <ArrowIcon />
          )}
        </S.WithActionButton>
      </S.WithActionContainer>
    ),
  }[type];

  return <S.Container>{componentType}</S.Container>;
};

export default Input;
