import React from "react";
import * as S from "./styles";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const ProductSkeleton = ({ minimal = false }) => {
  return (
    <S.Container minimal={minimal}>
      <S.ImageContainer></S.ImageContainer>
      <S.DescriptionContainer>
        <S.Title>
          <Skeleton count={1} />
        </S.Title>
        {minimal === false && (
          <S.Description>
            <Skeleton count={3} />
          </S.Description>
        )}
        <S.Price>
          <Skeleton count={1} />
        </S.Price>
      </S.DescriptionContainer>
    </S.Container>
  );
};

export default ProductSkeleton;
