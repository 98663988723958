export const PAYMENT_METHODS = {
  mercado_pago: "Mercado Pago",
  depsito_o_transferencia: "Depósito o Transferencia",
};

export const LINK_CHECKOUT = "https://link.mercadopago.com.uy/coralinejoyas";

export const ORDER_STATUS = {
  payment_pending: "Tu órden ha sido confirmada y está pendiente de pago",
  canceled: "Tu órden ha sido cancelada",
  complete: "Tu órden ha sido completada!",
  delivery_pending: "Tu órden ha sido confirmada y está pendiente de envío",
  pending: "Tu órden está pendiente",
};

export const FREE_SHIPPING_AMOUNT = 2000;

export const WP_CONTACT_NUMBER = "59898521660";
