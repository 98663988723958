import React from "react";
import * as S from "./styles";
import {
  NavComponent,
  SeoComponent,
  FooterComponent,
  BreadCrumbComponent,
} from "components";
import { useLocation } from "react-router-dom";
import { ScrollRestoration } from "react-router-dom";

const Common = ({
  title,
  subTitle,
  pageTitle,
  pageDescription,
  children,
  image,
  type,
  breadcrumbs = false,
  breadcrumbsCategory,
  loading = false,
}) => {
  const pathname = useLocation().pathname;

  return (
    <>
      <ScrollRestoration />
      <SeoComponent
        title={`Coraline Joyas${pageTitle ? ` | ${pageTitle}` : ""}`}
        description={pageDescription}
        image={image}
        type={type}
      />
      <S.Container>
        <NavComponent active={pathname} />
        {breadcrumbs && (
          <S.BreadContainer>
            {!loading && (
              <BreadCrumbComponent
                active={pathname}
                category={breadcrumbsCategory}
              />
            )}
          </S.BreadContainer>
        )}
        <S.TitleContainer>
          <S.Title>{title}</S.Title>
          {subTitle && <S.SubTitle>{subTitle}</S.SubTitle>}
        </S.TitleContainer>
        <S.Content>{children}</S.Content>
        <FooterComponent />
      </S.Container>
    </>
  );
};

export default Common;
