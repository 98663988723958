import React from "react";
import * as S from "./styles";
import {
  ProductComponent,
  ProductSkeletonComponent,
  NoProductsComponent,
} from "components";

const DEFAULT_PRODUCTS_LOAD = 50;

const ProductsList = ({
  products,
  productsCount = DEFAULT_PRODUCTS_LOAD,
  loading,
}) => {
  return (
    <S.Container>
      {products === null || loading
        ? [...new Array(productsCount)].map((e, index) => (
            <ProductSkeletonComponent key={`product-skeleton-${index}`} />
          ))
        : products.map((product, index) => (
            <ProductComponent
              key={`product-${product?.id || index}`}
              {...product}
            />
          ))}
      {products && products.length === 0 && <NoProductsComponent />}
    </S.Container>
  );
};

export default ProductsList;
