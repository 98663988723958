import React, { useEffect, useContext, useCallback } from "react";
import Routes from "routes";
import swell from "swell-js";
import { HelmetProvider } from "react-helmet-async";
import AppContextProvider from "context";
import { GlobalContext, DispatchTypes } from "context";
import { CategoryService, ProductService } from "services";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-tooltip/dist/react-tooltip.css";

function App() {
  swell.init(process.env.REACT_APP_SWELL_ID, process.env.REACT_APP_SWELL_KEY);

  const context = useContext(GlobalContext);
  const [swellState, swellDispatch] = context.globalSwell;
  const [, storeDispatch] = context.globalStore;

  const getCategories = useCallback(async () => {
    swellDispatch({ type: DispatchTypes.Swell.SET_START, entity: "category" });
    const categories = await CategoryService.getCategories();
    swellDispatch({
      type: DispatchTypes.Swell.SET_SUCCESS,
      entity: "category",
      value: categories,
    });
  }, [swellDispatch]);

  const getAttributes = useCallback(async () => {
    swellDispatch({
      type: DispatchTypes.Swell.SET_START,
      entity: "attributes",
    });
    const attributes = await swell.attributes.get();
    swellDispatch({
      type: DispatchTypes.Swell.SET_SUCCESS,
      entity: "attributes",
      value: attributes.results,
    });
  }, [swellDispatch]);

  const loadSwellSettings = useCallback(async () => {
    await swell.settings.load();
    getCategories();
    getAttributes();
  }, [getCategories, getAttributes]);

  const initStoreState = useCallback(() => {
    storeDispatch({
      type: DispatchTypes.Store.SET_START,
      entity: "wishList",
    });
    const newWishList = ProductService.getFavourites();
    storeDispatch({
      type: DispatchTypes.Store.SET_SUCCESS,
      entity: "wishList",
      value: newWishList,
    });
  }, [storeDispatch]);

  const getFeaturedProducts = useCallback(async () => {
    swellDispatch({
      type: DispatchTypes.Store.SET_START,
      entity: "featured",
    });
    try {
      const featured = await ProductService.getProducts({
        where: { "content.featured": true },
      });
      swellDispatch({
        type: DispatchTypes.Store.SET_SUCCESS,
        entity: "featured",
        value: featured.results,
      });
    } catch (e) {
      swellDispatch({
        type: DispatchTypes.Store.SET_ERROR,
        entity: "featured",
        value: e,
      });
    }
  }, [swellDispatch]);

  useEffect(() => {
    if (swellState.featured.value === null) {
      getFeaturedProducts();
    }
  }, [getFeaturedProducts, swellState.featured.value]);

  useEffect(() => {
    initStoreState();
  }, [initStoreState]);

  useEffect(() => {
    if (swell) {
      loadSwellSettings();
    }
  }, [loadSwellSettings]);

  return (
    <HelmetProvider>
      <AppContextProvider>
        <Routes />
        <ToastContainer />
      </AppContextProvider>
    </HelmetProvider>
  );
}

export default App;
