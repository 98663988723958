import React from "react";
import * as S from "./styles";

const BreadCrumb = ({ active, category }) => {
  const cleanPath = (path) => {
    const split = path.split("/").at(-1);
    const removeSlash = split.replaceAll("/", "").replaceAll("-", " ");
    return removeSlash.charAt(0).toUpperCase() + removeSlash.slice(1);
  };

  const transformLink = (path) => (path.charAt(0) === "/" ? path : `/${path}`);

  const breads = category ? [] : ["/productos"];

  if (active !== "/productos") {
    if (category) {
      breads.push(category);
    }
    breads.push(active);
  }

  return (
    <S.Container>
      {breads.map((bread, index) => {
        if (bread === active) {
          return (
            <S.NoLink key={`${bread}-${index}`}>{cleanPath(bread)}</S.NoLink>
          );
        } else {
          return (
            <span key={`link-${bread}-${index}`}>
              <S.Link to={transformLink(bread)}>{cleanPath(bread)}</S.Link>
              <span className="arrow">&gt;</span>
            </span>
          );
        }
      })}
    </S.Container>
  );
};

export default BreadCrumb;
