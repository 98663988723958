import styled from "styled-components";
import { breakpoints } from "configs/styles";

export const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #00000020;
  top: 0;
  left: 0;
  z-index: 998;
`;

export const Container = styled.div`
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  left: ${({ rtl }) => (rtl ? "auto" : 0)};
  right: ${({ rtl }) => (rtl ? 0 : "auto")};
  width: ${({ open }) => (open ? "auto" : "0px")};
  min-width: ${({ open }) => (open ? "300px" : "0px")};
  overflow: hidden;
  padding: 0;
  background-color: #fff;
  z-index: 999;
  transition: width 1s ease-in-out;
  transition: max-width 1s ease-in-out;
  height: 100vh;
  min-height: 100vh;
  @media (max-width: ${breakpoints.medium}) {
    height: 100%;
    min-height: 100%;
  }
`;
