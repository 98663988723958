import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { breakpoints } from "configs/styles";

export const Container = styled.nav`
  box-sizing: border-box;
  max-width: 100vw;
  padding: 20px 40px;
  display: flex;
  background: ${({ background, showDesktopMenu }) =>
    background || showDesktopMenu ? "#fff" : "transparent"};
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 10;

  ${({ background, showDesktopMenu }) =>
    (background || showDesktopMenu) &&
    `
  .cart {
    color: #333;
    img {
      filter: invert();
    }
  }
  `}

  @media (max-width: ${breakpoints.medium}) {
    height: 90px;
    background-color: #fff;
  }
`;

export const LogoContainer = styled.div`
  flex: 2;
  display: flex;
  align-items: center;
  @media (max-width: ${breakpoints.medium}) {
    flex: 1;
    justify-content: center;
    img {
      width: 170px;
    }
  }
`;

export const CartContainer = styled.div`
  flex: 2;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  color: #fff;
  text-transform: uppercase;

  > span {
    cursor: pointer;
    display: flex;
  }

  & img {
    margin-right: 15px;
  }
  @media (max-width: ${breakpoints.medium}) {
    span.cart-title {
      display: none;
    }
    color: #333;
    flex: 0;
    img {
      filter: invert();
      margin-right: 0;
    }
  }

  div.cart-icon {
    position: relative;
    span.items-count {
      position: absolute;
      left: 20px;
      top: -10px;
      width: 19px;
      height: 19px;
      border-radius: 50%;
      font-weight: 300;
      font-size: 11px;
      color: white;
      background-color: #f5917b;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;

export const MenuList = styled.ul`
  display: flex;
  justify-content: space-between;
  @media (max-width: ${breakpoints.medium}) {
    padding: 0;
  }
`;

export const MenuItem = styled.li`
  list-style: none;
  padding: 0 40px;
  position: relative;
`;

export const ArrowDropdown = styled.span`
  display: none;
  @media (max-width: ${breakpoints.medium}) {
    display: block;
    position: absolute;
    right: 20px;
    top: 4px;
    rotate: ${({ open }) => (open ? `-90deg` : `90deg`)};
  }
`;

export const SubMenuList = styled.ul`
  display: none;
  @media (max-width: ${breakpoints.medium}) {
    display: ${({ open }) => (open ? "flex" : "none")};
  }
  flex-direction: column;
  justify-content: space-between;
  background-color: #fbc6a8;
  margin: 0px -40px;
  gap: 10px;
  padding: 10px 40px;
`;

export const SubMenuItem = styled.li`
  list-style: none;
  padding: 0 20px;
`;

export const MenuLink = styled(NavLink)`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  text-transform: uppercase;
  text-decoration: none;
  color: #aaaaaa;
  width: fit-content;
  &:hover {
    color: #333333;
  }
  &.active {
    color: #333333;
    border-bottom: none;
  }
  &.inActive {
    color: #aaaaaa;
    border-bottom: none;
  }

  @media (max-width: ${breakpoints.medium}) {
    width: 100%;
    display: flex;
    justify-content: space-between;
    span.item {
      ${({ categories }) => categories && "margin-bottom: 10px"}
    }
  }
`;

export const MenuContainer = styled.div`
  flex: 3;
  @media (max-width: ${breakpoints.medium}) {
    display: ${({ open }) => (open ? "block" : "none")};
    position: fixed;
    top: 90px;
    left: 0;
    width: 100%;
    background-color: #f5917b;
    z-index: 10;
    ${MenuList} {
      flex-direction: column;
      gap: 15px;
      ${MenuLink} {
        color: #fff;
        &.active {
          > span.item {
            border-bottom: 1px solid #fff;
          }
        }
        &.inActive {
          > span.item {
            border-bottom: none;
          }
        }
      }
    }
  }
`;

export const MenuButton = styled.div`
  display: none;
  width: 20px;
  @media (max-width: ${breakpoints.medium}) {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const DesktopMenu = styled.div`
  transition: all 0.2s ease-in-out;
  position: fixed;
  top: 102px;
  width: 100%;
  left: 0;
  padding: 20px 60px;
  background-color: #fff;
  display: flex;
  visibility: ${({ show }) => (show ? "visible" : "hidden")};
  opacity: ${({ show }) => (show ? 1 : 0)};
  align-items: center;
  justify-content: center;
  box-shadow: 6px 3px 3px rgba(0, 0, 0, 0.25);

  @media (max-width: ${breakpoints.medium}) {
    display: none;
  }

  > div {
    max-width: 1000px;
    flex: 1;
    display: flex;
    justify-content: space-between;
  }
`;

export const DesktopMenuItemLink = styled(NavLink)`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  text-transform: uppercase;
  color: #333;
  &.inActive {
    text-decoration: none !important;
  }
`;

export const DesktopMenuItem = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  gap: 10px;
  flex-direction: column;
  li {
    padding-left: 10px;
  }
  li:first-child {
    padding-left: 0px;
    margin-bottom: 20px;
    ${DesktopMenuItemLink} {
      color: #fbc6a8;
      text-decoration: underline;
    }
  }
`;

// CART
export const CartSidebarContainer = styled.div`
  padding-top: 70px;
  max-width: 600px;
  width: 50vw;
  max-height: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: ${breakpoints.medium}) {
    width: 100vw;
  }

  .tooltip-width {
    max-width: 70%;
  }
`;

export const CartItemsContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 20px;
  flex: 1;
  overflow: auto;
  padding: 25px;
`;

export const CartItem = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 20px;

  img.productImage {
    width: 100%;
    height: auto;
  }

  h3 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    margin: 0;
    @media (max-width: ${breakpoints.medium}) {
      font-size: 17px;
    }
  }

  > div {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  > div:first-child {
    width: 121px;
    height: 121px;
    @media (max-width: ${breakpoints.medium}) {
      width: 80px;
      height: auto;
    }
  }
  > div:nth-child(2) {
    flex: 1;
  }
  > div:last-child {
    justify-content: center;
    align-items: center;

    position: relative;
  }

  div.description {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    color: #666666;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    margin: 0;
    @media (max-width: ${breakpoints.medium}) {
      font-size: 14px;
    }
  }

  p.price {
    margin: 10px 0 0 0;
    font-family: "Rajdhani";
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    color: #000000;
  }
`;

export const RemoveItem = styled.span`
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  ${({ disabled }) => disabled && `opacity: 0.5;`}
`;

export const QuantityInput = styled.div`
  ${({ isLoading }) => isLoading && `opacity: 0.5;`}
  display: flex;
  padding: 5px;
  border: 1px solid #eeeeee;
  justify-content: space-between;
  width: 80px;

  > span {
    :first-child,
    :last-child {
      cursor: pointer;
    }
    &.disabled {
      cursor: not-allowed;
      color: #aaa;
    }
  }
`;

export const CartActionContainer = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  padding: 25px;

  div.totals {
    width: 100%;
    p {
      :first-child,
      :last-child {
        border-top: 1px solid #cccccc;
        padding-top: 20px;
      }

      display: flex;
      justify-content: space-between;

      span:first-child {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        color: #555555;
      }

      span:last-child {
        font-family: "Rajdhani";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        text-align: right;
        color: #333333;
      }
    }
  }

  button {
    width: 100%;
    height: 62px;
    outline: none;
    border: none;
    background: #333333;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #eeeeee;
    cursor: pointer;
    margin: 0;
    position: relative;
    ::after {
      content: "";
      position: absolute;
      right: 3px;
      top: 3px;
      border-top: 0px solid transparent;
      border-bottom: 8px solid transparent;
      border-left: 8px solid #eeeeee;
      clear: both;
      width: 0;
      height: 0;
      rotate: 90deg;
    }
  }
`;

export const CloseButton = styled.span`
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
`;

export const NoItemsMessage = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
`;
