import React, { useContext } from "react";
import * as S from "./styles";
import { CommonLayout } from "layouts";
import { FeaturedListLayout } from "layouts";
import { GlobalContext } from "context";

const PageNotFound = () => {
  const context = useContext(GlobalContext);
  const [swellState] = context.globalSwell;

  return (
    <CommonLayout
      title="Página no encontrada"
      subTitle="No pudimos encontrar lo que estás buscando"
      pageTitle="Página no encontrada"
      pageDescription="No pudimos encontrar lo que estás buscando"
    >
      <S.Container>
        <S.Title>
          <strong>Oops!</strong>No pudimos encontrar lo que estás buscando.
        </S.Title>

        <S.FeaturedTitle>Quizás te pueda interesar</S.FeaturedTitle>
        <FeaturedListLayout
          products={swellState.featured.value}
          loading={swellState.featured.loading}
        />
      </S.Container>
    </CommonLayout>
  );
};

export default PageNotFound;
