const USER_DATA = "CORALINE_USER_DATA";
const WISH_LIST = "wishList";

const DEFAULT_SESSION = {
  [WISH_LIST]: [],
};

export const getUserSession = () => {
  const userData = localStorage.getItem(USER_DATA);
  if (userData) {
    return JSON.parse(localStorage.getItem(USER_DATA));
  } else {
    const newUserSession = JSON.stringify(DEFAULT_SESSION);
    localStorage.setItem(USER_DATA, newUserSession);
    return USER_DATA;
  }
};

export const setUserSession = (user) => {
  const userFromStorage = getUserSession();
  const newUser = {
    ...userFromStorage,
    ...user,
  };
  const userObject = JSON.stringify(newUser);
  localStorage.setItem(USER_DATA, userObject);
};

export const deleteUserSession = () => {
  localStorage.removeItem(USER_DATA);
};

export const addToWishList = (id) => {
  const userSession = getUserSession();
  const existOnWishList = userSession[WISH_LIST]?.find(
    (product) => product === id
  );
  if (!existOnWishList) {
    if (userSession.wishList) {
      userSession[WISH_LIST].push(id);
    } else {
      userSession[WISH_LIST] = [id];
    }
    setUserSession(userSession);
  }
  return userSession[WISH_LIST];
};

export const getWishList = () => {
  const userSession = getUserSession();
  return userSession[WISH_LIST] || [];
};

export const removeFromWishList = (id) => {
  const userSession = getUserSession();
  const existOnWishList = userSession[WISH_LIST]?.find(
    (product) => product === id
  );
  if (existOnWishList) {
    userSession[WISH_LIST] = userSession[WISH_LIST].filter(
      (product) => product !== id
    );
    setUserSession(userSession);
  }
  return userSession[WISH_LIST] || [];
};
