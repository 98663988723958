import React, { useState, useEffect, useContext, useCallback } from "react";
import * as S from "./styles";
import { CommonLayout, ProductsListLayout } from "layouts";
import { ProductService } from "services";
import { GlobalContext } from "context";

const Favourites = () => {
  const [products, setProducts] = useState(null);
  const context = useContext(GlobalContext);
  const [storeState] = context.globalStore;
  const favourites = storeState.wishList.value;

  const getProducts = useCallback(async () => {
    const products = await ProductService.getProducts({});
    const favouriteProducst = products.results.filter(({ id }) =>
      favourites.includes(id)
    );
    setProducts(favouriteProducst);
  }, [favourites]);

  useEffect(() => {
    if (favourites) {
      getProducts();
    }
  }, [favourites, getProducts]);

  return (
    <CommonLayout
      title="Favoritos"
      subTitle="Tus productos favoritos"
      pageTitle="Favoritos"
      pageDescription="Tus productos favoritos"
      breadcrumbs={true}
    >
      <S.Container>
        <ProductsListLayout
          products={products}
          loading={products === null}
          productsCount={favourites?.length || undefined}
        />
      </S.Container>
    </CommonLayout>
  );
};

export default Favourites;
