import styled, { keyframes } from "styled-components";
import { breakpoints } from "configs/styles";

export const Container = styled.div``;

export const Title = styled.h2`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 42px;
  text-align: center;
  position: relative;
  z-index: 1;
  margin-bottom: 70px;
  :after {
    content: "";
    width: 150px;
    background-color: #fbc6a8;
    height: 5px;
    position: absolute;
    bottom: -25px;
    left: calc(50% - 75px);
  }
  @media (max-width: ${breakpoints.medium}) {
    font-size: 32px;
    margin-bottom: 50px;
  }
`;

export const Content = styled.div`
  overflow: hidden;
  width: 100%;
  max-width: 100%;
  position: relative;
  min-height: 400px;
`;

const scroll = (categoriesLength, width) => keyframes`
	0% { transform: translateX(0); }
	100% { transform: translateX(calc(${width} * ${categoriesLength}))}
`;

export const ImagesContainer = styled.div`
  width: ${({ categoriesLength }) => `calc(400px * ${categoriesLength})`};
  animation: ${({ categoriesLength }) => scroll(categoriesLength, "-400px")} 10s
    linear infinite;
  display: flex;
  padding: 20px;
  position: relative;
  z-index: 1;
  align-content: center;
  display: flex;
  :hover {
    animation-play-state: paused;
  }
  @media (max-width: ${breakpoints.medium}) {
    width: ${({ categoriesLength }) => `calc(200px * ${categoriesLength})`};
    animation: ${({ categoriesLength }) => scroll(categoriesLength, "-200px")}
      10s linear infinite;
  }
  div {
    h2 {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 300;
      font-size: 40px;
      position: absolute;
      top: 120px;
      left: 0;
      z-index: 10;
      color: #fff;
      text-transform: uppercase;
      width: 471px;
      text-align: center;
      display: inline-block;
      white-space: break-spaces;
      word-wrap: break-word;
      padding: 0 100px;
    }
    cursor: pointer;
    width: 400px;
    position: relative;
    left: -100px;
    word-break: break-word;
    svg image {
      filter: brightness(50%);
    }
    :hover {
      svg {
        transition: 0.3s;
        filter: brightness(150%);
      }
    }
    @media (max-width: ${breakpoints.medium}) {
      width: 200px;
      svg {
        width: 260px;
        height: 300px;
      }
      h2 {
        top: 70px;
        font-size: 23px;
        width: 260px;
        padding: 0 50px;
      }
    }
  }
  div:nth-child(even) {
    margin-top: 70px;
    h2 {
      top: 160px;
    }
  }
`;
