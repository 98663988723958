import swell from "swell-js";
import {
  addToWishList,
  removeFromWishList,
  getWishList,
} from "configs/storage";

export const getProducts = async ({
  limit = 100,
  page = 1,
  sort = "stock_level desc",
  filters,
  where,
  search,
}) => {
  const products = await swell.products.list({
    limit,
    page,
    sort,
    $filters: filters,
    where,
    search,
  });
  return products;
};

export const getProductBySlug = async (slug) => {
  const product = await swell.products.get({
    slug,
    expand: "categories",
  });
  return product.results[0];
};

export const getProductsByCategory = async ({
  category,
  limit = 100,
  page = 1,
  sort = "stock_level desc",
  filters,
  where,
  search,
}) => {
  const products = await swell.products.list({
    category,
    limit,
    page,
    sort,
    $filters: filters,
    search,
    where,
  });
  return products;
};

export const getAttributes = async (id) => {
  const attributes = await swell.attributes.list({
    limit: 25,
    page: 1,
  });
  return attributes;
};

export const getFavourites = () => {
  return getWishList();
};

export const addToFavourites = (id) => {
  return addToWishList(id);
};

export const removeFromFavourites = (id) => {
  return removeFromWishList(id);
};
