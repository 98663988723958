import React, { useState, useEffect } from "react";
import * as S from "./styles";
import Skeleton from "react-loading-skeleton";
import ImagePlaceholder from "resources/images/placeholder-image.jpg";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

const Images = ({ images }) => {
  const [selected, setSelected] = useState(null);
  const DEFAULT_IMAGES_LOAD = 3;

  useEffect(() => {
    if (images) {
      setSelected(0);
    }
  }, [images]);

  const handleSelect = (number) => {
    setSelected(number);
  };

  const selectedUrl = selected !== null && images[selected]?.url;

  return (
    <S.Container>
      <S.ListContainer>
        {images !== undefined
          ? images.map(({ id, url }, index) => (
              <S.ListItem
                key={`${index}-${id}`}
                className={selected === index && "selected"}
                onClick={() => handleSelect(index)}
              >
                <img src={url} alt="" />
              </S.ListItem>
            ))
          : [...new Array(DEFAULT_IMAGES_LOAD)].map((e, index) => (
              <Skeleton height={100} width={100} key={`skeleton-${index}`} />
            ))}
      </S.ListContainer>
      <S.ImageContainer>
        {images !== undefined ? (
          <Zoom>
            <img src={selectedUrl || ImagePlaceholder} alt="product" />
          </Zoom>
        ) : (
          <Skeleton width="100%" height={400} />
        )}
      </S.ImageContainer>
    </S.Container>
  );
};

export default Images;
