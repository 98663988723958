import React from "react";
import * as S from "./styles";
import { ReactComponent as LikeIcon } from "resources/icons/like.svg";
import { ReactComponent as LikeFillIcon } from "resources/icons/likeFill.svg";
import { ReactComponent as CartIcon } from "resources/icons/cart.svg";

const Button = ({
  type,
  label,
  primaryAction,
  secondaryAction,
  disabled,
  favourite,
}) => {
  const buttonType = {
    addToCart: (
      <S.Container>
        <S.ButtonCartContainer>
          <S.ButtonCart disabled={disabled} onClick={primaryAction}>
            <CartIcon />
            {label}
          </S.ButtonCart>
          <S.ButtonLike onClick={secondaryAction}>
            {favourite ? <LikeFillIcon /> : <LikeIcon />}
          </S.ButtonLike>
        </S.ButtonCartContainer>
      </S.Container>
    ),
    secondary: (
      <S.Container>
        <S.ButtonCartContainer>
          <S.Primary disabled={disabled} onClick={primaryAction}>
            {label}
          </S.Primary>
        </S.ButtonCartContainer>
      </S.Container>
    ),
    common: (
      <S.Common disabled={disabled} onClick={primaryAction}>
        {label}
      </S.Common>
    ),
  }[type];

  return buttonType;
};

export default Button;
