import styled from "styled-components";
import { NavLink } from "react-router-dom";

export const Container = styled.div`
  display: flex;
  padding: 0;
  flex-direction: column;
  flex: 1;
  width: 100%;
  max-width: 1200px;

  h3 {
    color: #333;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
  }
`;

export const CategoriesContainer = styled.div`
  display: flex;
  gap: 10px;
  overflow-x: scroll;
  padding-bottom: 20px;
  padding-top: 5px;
`;

export const Link = styled(NavLink)`
  display: flex;
  border-radius: 8px;
  background: #f5917b;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  text-decoration: none;
  white-space: nowrap;
  padding: 0 25px;
`;
