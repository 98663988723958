import swell from "swell-js";

export const getCartItems = async () => {
  return await swell.cart.get();
};

export const addCartItem = async ({ product_id, quantity = 1, options }) => {
  const item = await swell.cart.addItem({
    product_id,
    quantity,
    options,
  });
  return item;
};

export const removeCartItem = async (id) => {
  const cart = await swell.cart.removeItem(id);
  return cart;
};

export const updateCartItem = async (id, quantity) => {
  const cart = await swell.cart.updateItem(id, {
    quantity,
  });
  return cart;
};

export const getOrder = async (id) => {
  return await swell.cart.getOrder(id);
};

export const getShipments = async () => {
  return await swell.get("/shipments");
};
