import styled from "styled-components";
import { breakpoints } from "configs/styles";
import { NavLink } from "react-router-dom";

export const Container = styled.div`
  display: inline-block;
`;

const Link = styled(NavLink)`
  text-decoration: none;
  width: 254px;
  height: 65px;
  border-radius: 20px;
  font-weight: 600;
  font-size: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 100%;
  cursor: pointer;
  @media (max-width: ${breakpoints.medium}) {
    font-size: 18px;
    height: 39px;
    width: 150px;
    border-radius: 10px;
  }
`;

export const LinkPrimary = styled(Link)`
  background: #fbc6a8;
  color: #fff;
`;

export const LinkSecondary = styled(Link)`
  border: 2px solid #fbc6a8;
  color: #fbc6a8;
  font-weight: 500;
`;

export const Button = styled.button`
  text-decoration: none;
  outline: none;
  border: none;
  width: 254px;
  height: 65px;
  border-radius: 20px;
  font-weight: 600;
  font-size: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 100%;
  cursor: pointer;
  background: #fbc6a8;
  color: #fff;
  @media (max-width: ${breakpoints.medium}) {
    font-size: 18px;
    height: 39px;
    width: 150px;
    border-radius: 10px;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;
