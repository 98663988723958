import React, { useContext } from "react";
import * as S from "./styles";
import { FeaturedListLayout } from "layouts";
import { GlobalContext } from "context";

const NoProducts = () => {
  const context = useContext(GlobalContext);
  const [swellState] = context.globalSwell;

  return (
    <S.Container>
      <S.NoProducts>No hay productos disponibles</S.NoProducts>
      <S.FeaturedTitle>Quizás te pueda interesar</S.FeaturedTitle>
      <FeaturedListLayout
        products={swellState.featured.value}
        loading={swellState.featured.loading}
      />
    </S.Container>
  );
};

export default NoProducts;
