import React, { useState, useEffect } from "react";
import * as S from "./styles";

const QuantitySelector = ({ value, maxValue, onChange }) => {
  const [quantity, setQuantity] = useState(value);

  useEffect(() => {
    setQuantity(value);
  }, [value]);

  const handleQuantityChange = (newValue) => {
    const newQuantity = newValue < 0 ? 0 : newValue;
    if (newValue <= maxValue) {
      setQuantity(newQuantity);
      onChange(newQuantity);
    }
  };

  return (
    <S.Container>
      <S.QuantityButton
        onClick={() => quantity > 1 && handleQuantityChange(quantity - 1)}
        disabled={quantity <= 1}
      >
        -
      </S.QuantityButton>
      <S.QuantityValue>{quantity}</S.QuantityValue>
      <S.QuantityButton
        onClick={() =>
          quantity < maxValue && handleQuantityChange(quantity + 1)
        }
        disabled={quantity >= maxValue}
      >
        +
      </S.QuantityButton>
    </S.Container>
  );
};

export default QuantitySelector;
