import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  width: 100px;
  height: 42px;
  border: 1px solid #999;
`;

export const QuantityButton = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  ${({ disabled }) => disabled && `color: #999;`}
`;

export const QuantityValue = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
