import styled from "styled-components";
import HomeBg from "resources/images/home-bg.svg";
import { breakpoints } from "configs/styles";

export const Container = styled.div`
  background: url(${HomeBg}) no-repeat top right;
  overflow: hidden;
  padding-top: 90px;
`;

export const TitleContainer = styled.div`
  background-color: #f5917b;
  text-align: center;
  padding: 15px 0;
`;

export const Title = styled.h1`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  color: #fff;
  margin: 0;
  min-height: 72px;
  padding: 0 20px;
  @media (max-width: ${breakpoints.medium}) {
    font-size: 32px;
    min-height: auto;
  }
`;

export const SubTitle = styled.h2`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  color: #fff;
  margin: -10px 0 0 0;
  min-height: 23px;
  padding: 0 20px;
`;

export const Content = styled.div`
  background-color: #fff;
  display: flex;
  justify-content: center;
`;

export const BreadContainer = styled.div`
  padding: 10px 40px;
`;
