import React, { useState, createRef } from "react";
import * as S from "./styles";
import FooterLogo from "resources/images/logo-footer.svg";
import InstagramIcon from "resources/icons/instagram.svg";
import WhatsappIcon from "resources/icons/whatsapp.svg";
import EmailIcon from "resources/icons/email.svg";
import { InputComponent } from "components";
import { UserServices } from "services";
import { validateEmail } from "utils/validators";
import { NEW_ACCOUNT_ERRORS } from "utils/errorMessages";
import { WP_CONTACT_NUMBER } from "utils/general";
import ReCAPTCHA from "react-google-recaptcha";
import { toast } from "react-toastify";
import MercadoPago from "resources/images/mercadopago.webp";

const Footer = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const recaptchaRef = createRef();

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleNewsLetter = async () => {
    if (email) {
      try {
        if (validateEmail(email)) {
          const token = recaptchaRef.current.executeAsync();
          if (token) {
            setLoading(true);
            const newUser = await UserServices.createNewsletter({ email });
            if (newUser.id) {
            } else {
              if (NEW_ACCOUNT_ERRORS[newUser.email?.code]) {
                toast.error(NEW_ACCOUNT_ERRORS[newUser.email?.code]);
              } else {
                toast.error(NEW_ACCOUNT_ERRORS.GENERIC);
              }
            }
            setLoading(false);
          }
        } else {
          toast.error(NEW_ACCOUNT_ERRORS.FORMAT_EMAIL);
        }
      } catch (e) {
        toast.error(NEW_ACCOUNT_ERRORS.GENERIC);
        setLoading(false);
      }
    }
  };

  return (
    <S.Container>
      <div>
        <img src={FooterLogo} alt="logo" />
      </div>
      <div>
        <div>
          <p>Joyas de plata 925</p>
          <p>Envíos a todo Uruguay</p>
          <p>Aceptamos transferencias y todas las tarjetas</p>
          <p>
            <img
              className="mercado-pago"
              src={MercadoPago}
              alt="mercado pago logo"
            />
          </p>
        </div>
      </div>
      <div>
        <h3>Boletín</h3>
        <InputComponent
          type="withButton"
          inputType="email"
          placeholder="tuemail@dominio.com"
          value={email}
          onChange={handleEmailChange}
          onAction={handleNewsLetter}
          loading={loading}
        />
        <ReCAPTCHA
          size="invisible"
          sitekey="6LckGW8nAAAAAEnrYRwDK9r5m4W4AIGV2r6oKGG-"
          ref={recaptchaRef}
        />
        <p>Suscribite para conocer las nuevas colecciones y nuestras ofertas</p>
      </div>
      <div>
        <h3>Contacto</h3>
        <p>
          <a
            target="_blank"
            href="https://www.instagram.com/coralinejoyas"
            rel="noreferrer"
          >
            <img src={InstagramIcon} alt="instagram icon" />
          </a>
          <a
            target="_blank"
            href={`https://wa.me/${WP_CONTACT_NUMBER}?text=Hola!`}
            rel="noreferrer"
          >
            <img src={WhatsappIcon} alt="whatsapp icon" />
          </a>
          <a
            target="_blank"
            href="mailto:contacto@coraline.uy"
            rel="noreferrer"
          >
            <img src={EmailIcon} alt="email icon" />
          </a>
        </p>
        <p>Consultas o sugerencias?</p>
        <p>Queremos conocerte!</p>
      </div>
    </S.Container>
  );
};

export default Footer;
