import React, { useReducer, createContext } from "react";
import SwellReducer, { swellInitialState } from "./reducers/swell";
import StoreReducer, { storeInitialState } from "./reducers/store";
import * as Types from "./types";

export const GlobalContext = createContext();

export const DispatchTypes = Types;

const AppContextProvider = ({ children }) => {
  const [swellState, swellDispatch] = useReducer(
    SwellReducer,
    swellInitialState
  );

  const [storeState, storeDispatch] = useReducer(
    StoreReducer,
    storeInitialState
  );

  const values = {
    globalSwell: [swellState, swellDispatch],
    globalStore: [storeState, storeDispatch],
  };

  return (
    <GlobalContext.Provider value={values}>{children}</GlobalContext.Provider>
  );
};

export default AppContextProvider;
