import styled from "styled-components";
import { breakpoints } from "configs/styles";

export const Container = styled.div`
  display: inline-flex;
  gap: 30px;
  padding: 20px 40px;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  max-width: 1200px;
  align-content: flex-start;
  @media (max-width: ${breakpoints.medium}) {
    gap: 15px;
    padding: 20px 0;
  }
`;

export const NoProducts = styled.p`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 34px;
`;
