import styled from "styled-components";
import Select from "react-select";

export const Container = styled.div`
  display: flex;
`;

export const Dropdown = styled(Select)`
  width: 100%;

  .select__multi-value {
    background-color: #f5917b;
    color: white;
    .select__multi-value__label {
      color: white;
    }
  }
`;
