import styled from "styled-components";

export const Container = styled.div`
  display: inline-block;
`;

const Button = styled.button`
  height: 46px;
  font-weight: 300;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 100%;
  cursor: pointer;
  outline: none;
  border: none;
  text-transform: uppercase;
  &focus,
  &:active {
    background-color: #f5917b;
  }
`;

export const ButtonCartContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const ButtonCart = styled(Button)`
  background: #fbc6a8;
  font-weight: 300;
  font-family: Poppins;
  color: #fff;
  flex: 1;
  margin-right: 5px;
  display: flex;
  gap: 10px;
  svg {
    width: 19px;
    path {
      fill: #fff;
    }
  }

  :disabled {
    opacity: 0.5;
  }
`;

export const Primary = styled(Button)`
  border: 1px solid #fbc6a8;
  background: none;
  font-weight: 300;
  font-family: Poppins;
  color: #fbc6a8;
  flex: 1;
  display: flex;
  gap: 10px;
  svg {
    width: 19px;
    path {
      fill: #fff;
    }
  }
  ${({ disabled }) => disabled && "opacity: 0.7"}
`;

export const ButtonLike = styled(Button)`
  background: #fbc6a8;
  padding: 0 10px;
  path {
    fill: #fff;
  }
`;

export const Common = styled.button`
  background-color: #333;
  color: #eee;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  width: 100%;
  height: 46px;
  position: relative;

  ::after {
    content: "";
    position: absolute;
    right: 3px;
    top: 3px;
    border-top: 0px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 8px solid #eeeeee;
    clear: both;
    width: 0;
    height: 0;
    rotate: 90deg;
  }
`;
