import styled from "styled-components";
import { breakpoints } from "configs/styles";

const heightDiff = "25vh";

export const Container = styled.div`
  position: relative;
  min-height: 600px;
  margin-top: 100px;
  @media (max-width: ${breakpoints.medium}) {
    min-height: calc(100% - ${heightDiff});
    height: calc(100vh - ${heightDiff});
  }
  img {
    width: 100%;
    height: 600px;
    @media (max-width: ${breakpoints.medium}) {
      min-height: calc(100% - ${heightDiff});
      height: calc(100vh - ${heightDiff});
    }
    object-fit: cover;
    object-position: center center;
  }
  .slick-dots {
    bottom: 25px;
    li button::before {
      color: #fff;
    }
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 150px;
  left: 100px;
  color: #fff;
  .scroll-animation {
    display: none;
  }
  @media (max-width: ${breakpoints.medium}) {
    top: 0;
    height: 100%;
    justify-content: space-between;
    padding: 100px 0 20px 0;
    .scroll-animation {
      max-width: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 30px;
    }
    left: 0;
    width: 100%;
    align-items: center;
    > div {
      text-align: center;
      h1 {
        text-align: center;
      }
    }
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 24px;
`;

export const ImageContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  & img {
    width: 100%;
    max-width: 600px;
  }
  @media (max-width: ${breakpoints.medium}) {
    justify-content: center;
    align-items: center;
    display: flex;
    padding-top: 80px;
    & img {
      width: 70%;
      max-width: 350px;
    }
  }
`;

export const Title = styled.h1`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 64px;
  max-width: 12ch;
  padding: 0;
  margin: 0 0 20px 0;
  & strong {
    font-weight: 500;
  }
  @media (max-width: ${breakpoints.medium}) {
    font-size: 42px;
  }
`;

export const FeaturedContainer = styled.div`
  background-color: #eeeeee;
  margin: 0;
  overflow: hidden;
  position: relative;
  :after {
    content: "";
    clip-path: polygon(60% 40%, 100% 0%, 100% 100%, 0% 100%);
    width: 100%;
    height: 100%;
    position: absolute;
    right: 0;
    top: 100px;
    background-color: #fbc6a8;
    z-index: 0;
  }
`;

export const AboutContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const FeaturedProductsContainer = styled.div`
  padding: 60px 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const AboutTitle = styled.h2`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 42px;
  text-align: center;
  position: relative;
  z-index: 1;
  :after {
    content: "";
    width: 150px;
    background-color: #fbc6a8;
    height: 5px;
    position: absolute;
    bottom: -25px;
    left: calc(50% - 75px);
  }
  @media (max-width: ${breakpoints.medium}) {
    font-size: 32px;
    margin-bottom: 50px;
  }
`;

export const AboutContent = styled.div`
  max-width: 1200px;
  width: 100%;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  padding: 10px 40px;
  strong {
    font-weight: 500;
  }
  @media (max-width: ${breakpoints.medium}) {
    padding: 10px 40px;
  }

  p.thanks {
    font-size: 19px;
  }
`;
