import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { breakpoints } from "configs/styles";

export const Container = styled.div`
  display: flex;
  gap: 6px;
  align-items: flex-end;
  @media (max-width: ${breakpoints.medium}) {
    gap: 4px;
  }
  margin: 10px 0;
  display: flex;
  align-items: center;
  > span {
    display: flex;
    align-items: center;
  }

  span.arrow {
    color: #f5917b;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 6px;
    @media (max-width: ${breakpoints.medium}) {
      margin-left: 4px;
    }
  }
`;

export const Link = styled(NavLink)`
  color: #555;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  text-decoration: none;
  @media (max-width: ${breakpoints.medium}) {
    font-size: 14px;
  }
`;

export const NoLink = styled.span`
  color: #000;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  text-decoration: none;
  @media (max-width: ${breakpoints.medium}) {
    font-size: 14px;
  }
`;
