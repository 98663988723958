import { DispatchTypes } from "..";

const internalStoreInitialState = {
  error: null,
  wishList: {
    loading: true,
    value: null,
    error: null,
  },
  filters: {
    loading: false,
    value: {},
    error: null,
  },
  loading: false,
};

export const storeInitialState = { ...internalStoreInitialState };

const StoreReducer = (currentState, action) => {
  switch (action.type) {
    case DispatchTypes.Store.SET_START:
      currentState[action.entity].loading = true;
      return { ...currentState };
    case DispatchTypes.Store.SET_SUCCESS:
      currentState[action.entity].value = action.value;
      currentState[action.entity].loading = false;
      return { ...currentState };
    case DispatchTypes.Store.SET_ERROR:
      currentState[action.entity].loading = false;
      currentState[action.entity].error = action.value;
      return { ...currentState };
    case DispatchTypes.Global.RESET:
      currentState = { ...storeInitialState };
      return { ...currentState };
    default:
      return currentState;
  }
};

export default StoreReducer;
