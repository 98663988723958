import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  padding: 20px;
  max-width: 1200px;
  width: 100%;
  min-height: 50vh;
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const Title = styled.h2`
  color: #f5917b;
  text-align: center;
  strong {
    font-size: 200%;
    display: block;
  }
`;

export const FeaturedTitle = styled.h3`
  color: #333;
  text-align: center;
  margin: 40px 0 0 0;
`;
