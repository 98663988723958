import React, { useEffect, useRef, useState } from "react";
import * as S from "./styles";
import { CommonLayout } from "layouts";
import { LinkComponent } from "components";
import { useForm } from "@formspree/react";
import ReCAPTCHA from "react-google-recaptcha";

const Cotnact = () => {
  const [state, handleSubmit] = useForm("mrgweqoo");
  const [showMsg, setShowMsg] = useState(true);
  const [captchaCheck, setCaptchaCheck] = useState(null);

  const customSubmit = (e) => {
    e?.preventDefault();
    if (captchaCheck) {
      handleSubmit(e);
    }
  };


  const contactForm = useRef(null);

  useEffect(() => {
    if (state.succeeded && contactForm) {
      contactForm?.current?.reset();
      setTimeout(() => {
        setShowMsg(false);
      }, 3000);
    }
  }, [state]);

  const onCaptchaChange = (value) => {
    if (value) {
      setCaptchaCheck(value);
    }
  };


  return (
    <CommonLayout
      title="Contacto"
      subTitle="Queremos saber de vos!"
      pageTitle="Contacto"
      pageDescription="Queremos saber de vos!"
    >
      <S.Container>
        <S.Form onSubmit={customSubmit} id="contact-form" ref={contactForm}>
          <div>
            <S.InputContainer>
              <S.Label htmlFor="form-nombre">Nombre</S.Label>
              <S.Input
                id="form-nomber"
                type="text"
                name="nombre"
                required
                placeholder="Escribe tu nombre"
                maxLength={30}
              />
            </S.InputContainer>

            <S.InputContainer>
              <S.Label htmlFor="form-email">Email</S.Label>
              <S.Input
                id="form-email"
                type="email"
                name="email"
                required
                placeholder="Escribe tu email"
                maxLength={50}
              />
            </S.InputContainer>
          </div>
          <div>
            <S.InputContainer className="text">
              <S.Label htmlFor="form-consulta">Consulta</S.Label>
              <S.TextArea
                or="form-consulta"
                name="consulta"
                placeholder="Dejanos tu mensaje!"
                required
                maxLength={500}
              ></S.TextArea>
            </S.InputContainer>
          </div>
          <div className="submit">
            <ReCAPTCHA
              sitekey="6LckGW8nAAAAAEnrYRwDK9r5m4W4AIGV2r6oKGG-"
              onChange={onCaptchaChange}
            />
            {captchaCheck === null && (
              <S.ErrorLabel>Debe completar el captcha</S.ErrorLabel>
            )}
            <LinkComponent
              type="button"
              label="Enviar"
              buttonType="submit"
              disabled={state.submitting || captchaCheck === null}
            />
          </div>
        </S.Form>
        {state.succeeded && showMsg && (
          <S.ThanksMsg>
            Gracias por tu mensaje! Te responderemos a la brevedad.
          </S.ThanksMsg>
        )}
      </S.Container>
    </CommonLayout>
  );
};

export default Cotnact;
