import React from "react";
import * as S from "./styles";

const Categories = ({ categories }) => {
  return (
    <S.Container>
      <h3>Categorías</h3>
      <S.CategoriesContainer>
        {categories.map(({ name, slug, id }) => (
          <S.Link to={`/${slug}`} key={id}>
            {name}
          </S.Link>
        ))}
      </S.CategoriesContainer>
    </S.Container>
  );
};

export default Categories;
