import styled from "styled-components";
import { breakpoints } from "configs/styles";

export const Container = styled.div`
  display: flex;
  padding: 20px 60px;
  max-width: 1200px;
  width: 100%;
  gap: 50px;
  flex: 1;
  @media (max-width: ${breakpoints.small}) {
    gap: 20px;
    flex-direction: column;
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  flex: 3;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 2;

  p.cart-quantity {
    margin: 0 0 10px 0;
  }

  div.show-cart-container {
    margin: 20px 0 0 0;
    padding: 0;
    width: 100%;
    > div {
      width: 100%;
    }
  }
`;

export const Title = styled.h1`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  color: #000000;
  margin: 0 0 20px 0;
`;

export const Description = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
`;

export const Price = styled.p`
  font-family: "Rajdhani";
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  margin: 10px 0;
`;

export const AttibuteContainer = styled.div`
  margin-bottom: 20px;
`;

export const AttibuteTitle = styled.h3`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  border-bottom: 1px solid #999999;
  margin: 20px 0;
`;

export const AttibuteContent = styled.div`
  &.share {
    display: flex;
    gap: 10px;
  }
`;

export const AvailableQuantity = styled.p`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  strong {
    font-weight: 500;
  }
`;

export const NoProducts = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 40px 0;
  strong {
    display: block;
  }
  a {
    margin-top: 25px;
  }
`;

export const NoStockContainer = styled.div`
  a {
    color: #f5917b;
  }
`;
