import React, { useState, useContext, useEffect, useCallback } from "react";
import * as S from "./styles";
import { ReactComponent as SearchIcon } from "resources/icons/search.svg";
import { ReactComponent as CloseIcon } from "resources/icons/close.svg";
import { GlobalContext, DispatchTypes } from "context";
import { ButtonComponent, DropdownComponent, CheckComponent } from "components";
import { ORDER_OPTIONS } from "utils/filters";

const Search = ({ getProducts }) => {
  const context = useContext(GlobalContext);
  const [swellState] = context.globalSwell;
  const [storeState, storeDispatch] = context.globalStore;

  const attributes = swellState.attributes.value;

  const filters = storeState.filters.value;

  const getProductsFormatted = ({
    clearSearch = false,
    clearFilters = false,
  }) => {
    const search = clearSearch || clearFilters ? "" : filters.search;
    const attributes = Object.keys(filters).filter(
      (key) => key.slice(0, 10) === "attributes" && filters[key].length
    );
    const sort = clearFilters ? undefined : filters.sort;
    const where = clearFilters
      ? undefined
      : attributes.reduce((acc, key) => {
          acc = { ...acc, [key]: { $in: filters[key] } };
          return acc;
        }, {});

    const filtersFormat = {
      sort,
      search,
      where,
    };
    getProducts(filtersFormat);
    setShowFilter(false);
  };

  const setFilters = (filtersProps) => {
    const newFilters = { ...filters, ...filtersProps };
    storeDispatch({
      type: DispatchTypes.Store.SET_SUCCESS,
      entity: "filters",
      value: newFilters,
    });
  };

  const clearFilters = useCallback(() => {
    storeDispatch({
      type: DispatchTypes.Store.SET_SUCCESS,
      entity: "filters",
      value: {},
    });
  }, [storeDispatch]);

  useEffect(() => {
    clearFilters();
  }, [clearFilters]);

  const search = filters?.search || "";
  const [showFilter, setShowFilter] = useState(false);

  const handleSearchChange = (e) => {
    setFilters({ search: e.target.value });
  };

  const handleStopPropagation = (e) => e.stopPropagation();

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      event.target.blur();
      getProductsFormatted({});
    }
  };

  const onFilterChange = (key, value, check = false) => {
    const newFilters = { ...filters };

    if (check) {
      if (newFilters[key] === undefined) {
        newFilters[key] = [];
      }
      if (value.value) {
        newFilters[key].push(value.label);
      } else {
        newFilters[key] = newFilters[key].filter((e) => e !== value.label);
      }
    } else {
      newFilters[key] = value;
    }
    setFilters(newFilters);
  };

  const applyFilters = () => {
    getProductsFormatted({});
  };

  const defaultSortOption =
    ORDER_OPTIONS.find(({ value }) => value === filters.sort)?.label ||
    undefined;

  const defaultSortValue =
    ORDER_OPTIONS.find(({ value }) => value === filters.sort) || "";

  const clearSearch = () => {
    if (filters.search !== "") {
      setFilters({ search: "" });
      getProductsFormatted({ clearSearch: true });
    }
  };

  return (
    <S.Container>
      <S.SearchInputContainer>
        <S.SearchInput
          value={search}
          onChange={handleSearchChange}
          placeholder="Buscar..."
          onKeyDown={handleKeyDown}
        />
        {search && (
          <CloseIcon className="closeIconSearch" onClick={clearSearch} />
        )}
        <SearchIcon onClick={getProductsFormatted} />
      </S.SearchInputContainer>
      <S.FilterButtonContainer onClick={() => setShowFilter(true)}>
        Filtros
      </S.FilterButtonContainer>
      <S.FilterContainer onClick={() => setShowFilter(false)} show={showFilter}>
        <S.SideBar onClick={handleStopPropagation}>
          <S.SideBarHeaderClose>
            <CloseIcon onClick={() => setShowFilter(false)} />
          </S.SideBarHeaderClose>
          <S.SideBarHeader>
            <S.SideBarHeaderTitle>Filtros</S.SideBarHeaderTitle>
          </S.SideBarHeader>
          <S.SideBarContainer>
            <S.AttributeContainer>
              <h4>Órden</h4>
              <DropdownComponent
                placeholder="Seleccionar..."
                options={ORDER_OPTIONS}
                onChange={({ value }) => onFilterChange("sort", value)}
                defaultValue={defaultSortOption}
                value={defaultSortValue}
              />
            </S.AttributeContainer>

            {attributes
              .filter(({ visible }) => visible)
              .map((attribute) => {
                const options =
                  attribute?.values?.map((item) => ({
                    value: item,
                    label: item,
                  })) || [];
                const values = attribute?.values?.filter((item) =>
                  (filters[`attributes.${attribute.id}`] || []).includes(item)
                ) || [];

                return (
                  <S.AttributeContainer key={attribute.id}>
                    <h4>{attribute.name}</h4>
                    {options
                      .sort((a, b) => (a.label < b.label ? -1 : 1))
                      .map(({ label, value }) => (
                        <CheckComponent
                          key={`${attribute.id}-${value}`}
                          name={attribute.id}
                          label={label}
                          id={value}
                          checked={values.includes(label)}
                          onChange={(e) =>
                            onFilterChange(
                              `attributes.${attribute.id}`,
                              { label, value: e.target.checked },
                              true
                            )
                          }
                        />
                      ))}
                  </S.AttributeContainer>
                );
              })}
          </S.SideBarContainer>
          <S.SideBarActionContainer>
            <button onClick={clearFilters} className="clearFilters">
              Limpiar filtros
            </button>
            <ButtonComponent
              label="Aplicar"
              type="common"
              primaryAction={applyFilters}
            />
          </S.SideBarActionContainer>
        </S.SideBar>
      </S.FilterContainer>
    </S.Container>
  );
};

export default Search;
