const Diamond = ({ image, rotated, index }) => {
  return (
    <svg
      width="471"
      height="447"
      viewBox="0 0 471 447"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask id={`imageMask-${index}`}>
        {rotated ? (
          <path
            d="M274.014 17.8896C254.873 -6.70353 217.419 -5.79099 199.498 19.7053L11.2539 259.135C-3.34843 276.076 -3.78719 301.03 10.2105 318.474L99.3006 429.501C111.366 444.537 131.448 450.342 150.001 445.122C213.809 427.171 253.08 427.494 311.678 444.884C329.917 450.297 349.817 445.127 362.22 430.694L459.791 317.16C474.182 300.414 474.779 275.839 461.216 258.413L274.014 17.8896Z"
            fill="white"
          />
        ) : (
          <path
            d="M274.014 429.11C254.873 453.704 217.419 452.791 199.498 427.295L11.2539 187.865C-3.34843 170.924 -3.78719 145.97 10.2105 128.526L99.3006 17.4993C111.366 2.46329 131.448 -3.34166 150.001 1.87792C213.809 19.829 253.08 19.5057 311.678 2.11556C329.917 -3.29735 349.817 1.87313 362.22 16.3056L459.791 129.84C474.182 146.586 474.779 171.161 461.216 188.587L274.014 429.11Z"
            fill="white"
          />
        )}
      </mask>

      <image
        height="447"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        xlinkHref={image}
        mask={`url(#imageMask-${index})`}
      ></image>
    </svg>
  );
};
export default Diamond;
