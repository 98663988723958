import styled from "styled-components";
import { breakpoints } from "configs/styles";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 60px 30px;
  justify-content: center;
  width: 100%;
  max-width: 1200px;
  a.mailto {
    color: #e74c3c;
  }
`;

export const OrderNumber = styled.h2`
  display: flex;
  align-items: center;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 300;
  img {
    margin-right: 10px;
  }
`;

export const OrderMessage = styled.h3`
  font-family: Poppins;
  font-size: 20px;
  font-weight: 300;
  font-style: italic;
`;

export const MercadoPagoContainer = styled.div`
  font-family: Poppins;
  font-size: 20px;
  h4 {
    font-weight: 500;
  }
`;

export const InfoContainer = styled.div`
  h3 {
    font-weight: 300;
  }
  border: 1px solid #ccc;
  padding: 50px;
  display: flex;
  margin: 30px 0;
  flex-direction: column;
  > div.columns-container {
    display: flex;
    flex: 1;
    @media (max-width: ${breakpoints.medium}) {
      flex-direction: column;
      gap: 20px;
    }
    > div {
      flex: 1;
      > div {
        margin-bottom: 20px;
        p {
          white-space: pre-line;
        }
      }
    }
  }

  .items-container {
    ul {
      list-style: none;
      li {
        display: flex;
        gap: 15px;
        img {
          width: 50px;
        }
      }
    }
  }
`;

export const NotFound = styled.div`
  font-family: Poppins;
  font-size: 26px;
  font-weight: 300;
  text-align: center;
  padding: 50px 0 100px 0;
`;

export const Loader = styled.div`
  font-family: Poppins;
  font-size: 26px;
  font-weight: 300;
  text-align: center;
  padding: 50px 0 100px 0;
`;
