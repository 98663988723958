import styled from "styled-components";
import SimpleCheckIcon from "resources/icons/simpleCheck.svg";

export const Container = styled.div`
  display: flex;
  width: 100%;
  box-sizing: border-box;

  input {
    display: none;
  }

  label {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  label:before {
    background-color: #fff;
    border: 1px solid #cacece;
    box-shadow: none;
    display: inline-block;
    transition-duration: 0.3s;
    width: 13px;
    height: 13px;
    content: "";
    margin: 0 10px 0 0;
    border-radius: 3px;
  }

  input:checked + label:before {
    border: 1px solid #f5917b;
    width: 13px;
    height: 13px;
    margin: 0 10px 0 0;
    box-shadow: none;
    background-color: #f5917b;
    background-image: url(${SimpleCheckIcon});
    background-position: center center;
    background-repeat: no-repeat;
  }
`;
