import styled from "styled-components";

export const Container = styled.div`
  max-width: 1200px;
  min-width: 100%;
  width: 100%;
  height: 340px;
  overflow: hidden;
  padding: 20px 0;

  .slick-slider {
    height: 100%;
  }

  .slick-track {
    padding: 10px;
  }

  button.slick-arrow {
    z-index: 1;
    &::before {
      color: #333;
    }
    &.slick-prev {
      left: 0px;
    }
    &.slick-next {
      right: 0px;
    }
  }
`;

export const NoProducts = styled.p`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 34px;
`;

export const ProductContainer = styled.div``;
