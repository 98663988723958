import styled from "styled-components";

export const Container = styled.div`
  display: inline-block;
  width: 100%;
`;

export const Input = styled.input`
  height: 36px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  padding: 6px;
`;

export const WithActionContainer = styled.div`
  display: flex;
  width: 100%;
  flex: 1;
`;

export const WithActionButton = styled.button`
  outline: none;
  background: #fbc6a8;
  width: 38px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 2px;
  cursor: pointer;
`;

export const WithActionInput = styled(Input)`
  display: flex;
  outline: none;
  border: none;
  flex: 1;
`;
