import styled from "styled-components";
import { breakpoints } from "configs/styles";

export const Container = styled.div`
  height: 300px;
  background: #444;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 70px 40px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  color: #fff;
  gap: 10%;
  > div {
    flex: 1;
    :first-child {
      flex: 0.1;
    }
    :first-child,
    :nth-child(2) {
      height: 100%;
      display: flex;
      align-items: center;
    }
    :nth-child(3) {
      flex: 1.5;
      max-width: 400px;
      p {
        margin-top: 20px;
      }
    }
    :last-child {
      width: fit-content;
      max-width: fit-content;
      padding-left: 5%;
      p:nth-child(2) {
        margin: 0 0 20px 0;
        display: flex;
        justify-content: space-between;
        max-width: 140px;
      }
    }
    p {
      margin: 0;
      a {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    h3 {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      margin-top: 0;
    }
  }
  @media (max-width: ${breakpoints.medium}) {
    flex-direction: column;
    height: auto;
    > div {
      margin-bottom: 25px;
      :last-child {
        padding-left: 0;
      }
    }
  }

  img.mercado-pago {
    width: 100px;
    margin-top: 15px;
  }
`;
