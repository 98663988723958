import swell from "swell-js";

export const getCategories = async () => {
  const categories = await swell.categories.list({
    limit: 100,
    page: 1,
    expand: "products",
  });
  return categories;
};

export const getCategory = async (id) => {
  const category = await swell.categories.get({
    id,
    expand: "products:100",
    limit: 100,
  });
  return category;
};
